/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { Button, ButtonProps, Modal, Typography } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../loader';
import { buttonBgColor } from '../../constants/colors';

interface ConfirmationModalProps {
  open: boolean;
  message?: string;
  children?: ReactJSXElement | string | null;
  onClose: (reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onClick: () => Promise<any>;
  hideBackDrop?: boolean;
  headerTitle?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  buttonBg?: string;
  shouldHandleLoading?: boolean;
  icon?: ReactNode;
  showActions?: boolean;
  modalWrapperStyles?: SerializedStyles;
  modalContainerStyles?: SerializedStyles;
  modalHeaderContainerStyles?: SerializedStyles;
  modalHeaderStyles?: SerializedStyles;
  modalMessageStyles?: SerializedStyles;
  modalActionsContainerStyles?: SerializedStyles;
  okButtonStyles?: SerializedStyles;
  okButtonProps?: Omit<Partial<ButtonProps>, 'onClick'>;
  cancelButtonStyles?: SerializedStyles;
  cancelButtonProps?: Omit<Partial<ButtonProps>, 'onClick'>;
  isOkButtonDisabled?: boolean;
  showCloseIcon?: boolean;
  isLoading?: boolean;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  message,
  children,
  onClick,
  onClose,
  hideBackDrop = false,
  headerTitle,
  okButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  buttonBg = buttonBgColor.SUCCESS_BG_COLOR,
  shouldHandleLoading = true,
  icon,
  showActions = true,
  modalWrapperStyles,
  modalContainerStyles,
  modalHeaderContainerStyles,
  modalHeaderStyles,
  modalMessageStyles,
  modalActionsContainerStyles,
  okButtonStyles,
  okButtonProps,
  cancelButtonStyles,
  cancelButtonProps,
  isOkButtonDisabled = false,
  showCloseIcon = false,
  isLoading = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    if (shouldHandleLoading) {
      setLoading(true);
      await onClick();
      setLoading(false);
    } else {
      onClick();
    }
  };
  return (
    <Modal
      open={open}
      onClose={(_, reason) => onClose(reason)}
      hideBackdrop={hideBackDrop}
      css={css`
        outline: none;
        &:focus-visible {
          outline: none;
        }
        ${modalWrapperStyles}
      `}
    >
      <div
        css={css(
          `
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            padding: 16px;
            border-radius: 4px;
            overflow: hidden;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            outline: none;
            &:focus-within {
              outline: none;
            }
          `,
          modalContainerStyles
        )}
      >
        {(headerTitle || icon) && (
          <div
            css={css(
              `
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                column-gap: 12px;
              `,
              modalHeaderContainerStyles
            )}
          >
            {icon ? icon : null}
            {headerTitle && (
              <Typography
                variant={'h2'}
                css={css(
                  `
                    font-size: 20px;
                    font-weight: 600;
                    padding-bottom: 20px;
                  `,
                  modalHeaderStyles
                )}
              >
                {headerTitle}
              </Typography>
            )}
            {showCloseIcon && (
              <CloseIcon
                css={css`
                  cursor: pointer;
                  color: #414141;
                  font-size: 24px;
                  margin-left: auto;
                `}
                onClick={() => {
                  onClose();
                }}
              />
            )}
          </div>
        )}
        {message && (
          <Typography
            css={css(
              `
                font-size: 18px;
                margin-bottom: 24px;
              `,
              modalMessageStyles
            )}
          >
            {message}
          </Typography>
        )}
        {children ? children : null}
        {showActions && (
          <div
            css={css(
              `
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
              `,
              modalActionsContainerStyles
            )}
          >
            <Button
              variant={'text'}
              css={css(
                `
                  margin-right: 16px;
                  padding: 4px 12px;
                  font-size: 16px;
                  background: none;
                  color: #414141;

                  :hover {
                    background: #efefef90;
                  }
                `,
                cancelButtonStyles
              )}
              onClick={() => onClose()}
              {...cancelButtonProps}
            >
              {cancelButtonText}
            </Button>
            <Button
              css={css(
                `
                  padding: 4px 16px;
                  font-size: 16px;
                  color: white;
                  background: ${loading || isLoading ? '#7a7a7a12' : buttonBg};
                  :hover {
                    background: ${
                      loading || isLoading
                        ? 'none'
                        : buttonBg === buttonBgColor.SUCCESS_BG_COLOR
                          ? '#2c644c'
                          : '#b30828'
                    };
                  }
                `,
                okButtonStyles
              )}
              disabled={loading || isLoading || isOkButtonDisabled}
              color="error"
              onClick={handleSubmit}
              {...okButtonProps}
            >
              {loading || isLoading ? <Loader size={24} /> : okButtonText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

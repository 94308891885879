import { useCallback, useEffect, useRef } from 'react';
import { useAtom, PrimitiveAtom } from 'jotai';
import { QueryRequest } from '../typings/request';
import useRefresh, { UseRefreshArgs } from './useRefresh';

type WithInitialValue<Value> = {
  init: Value;
};

type QueryRequestParams<T> = {
  requestAtom: PrimitiveAtom<QueryRequest<T>> &
    WithInitialValue<QueryRequest<T>>;
  queryFunction?: (...args: any) => Promise<T>;
  refreshEventType?: UseRefreshArgs['refreshEventType'];
  autoFetch?: boolean;
  cacheData?: boolean;
};

type QueryResponseParams<T> = {
  loading: boolean;
  refreshing: boolean;
  error?: Error;
  data?: T;
};

type QueryHookResponse<T> = {
  requestData: QueryRequest<T>;
  setData: (data: T) => void;
  setLoading: (loading: boolean) => void;
  setRefreshing: (refreshing: boolean) => void;
  fetchData: (refresh?: boolean) => Promise<QueryResponseParams<T>>;
  refreshData: () => Promise<void>;
};

export default function useQuery<T>({
  requestAtom,
  queryFunction,
  refreshEventType,
  autoFetch = true,
  cacheData,
}: QueryRequestParams<T>): QueryHookResponse<T> {
  const [requestData, setRequestData] = useAtom(requestAtom);
  const dataRef = useRef(requestData?.data);
  dataRef.current = requestData?.data;

  const setData = useCallback(
    (data: T) => {
      setRequestData({ ...requestData, data });
    },
    [requestData, setRequestData]
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      setRequestData({ ...requestData, loading });
    },
    [requestData, setRequestData]
  );

  const setRefreshing = useCallback(
    (refreshing: boolean) => {
      setRequestData({ ...requestData, refreshing });
    },
    [requestData, setRequestData]
  );

  const fetchData = useCallback(
    async (refresh?: boolean) => {
      if (typeof queryFunction !== 'function') {
        const response: QueryResponseParams<T> = {
          data: undefined,
          loading: false,
          error: undefined,
          refreshing: false,
        };
        setRequestData((prev) => {
          return { ...prev, ...response };
        });
        return Promise.resolve(response);
      }

      if (!refresh && cacheData && dataRef.current) {
        const response: QueryResponseParams<T> = {
          data: dataRef.current,
          loading: false,
          error: undefined,
          refreshing: false,
        };
        setRequestData((prev) => {
          return { ...prev, ...response };
        });
        return Promise.resolve(response);
      }

      setRequestData((prev) => {
        return {
          ...prev,
          loading: !refresh,
          refreshing: refresh,
          error: undefined,
        };
      });

      try {
        const data = await queryFunction();
        const response: QueryResponseParams<T> = {
          loading: false,
          refreshing: false,
          error: undefined,
          data,
        };
        setRequestData((prev) => ({ ...prev, ...response }));
        return response;
      } catch (error) {
        const response: QueryResponseParams<T> = {
          loading: false,
          refreshing: false,
          error: error as Error,
          data: undefined,
        };
        setRequestData((prev) => ({ ...prev, ...response }));
        return response;
      }
    },
    [cacheData, queryFunction, setRequestData]
  );

  const refreshData = useCallback(async () => {
    fetchData(true);
  }, [fetchData]);

  useRefresh({ refreshEventType, onRefresh: refreshData });

  useEffect(() => {
    if (autoFetch) {
      fetchData();
    } else {
      setRequestData((prev) => ({
        ...prev,
        loading: false,
        refreshing: false,
        error: undefined,
      }));
    }
  }, [fetchData, autoFetch]);

  return {
    requestData,
    setData,
    setLoading,
    setRefreshing,
    fetchData,
    refreshData,
  };
}

/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import StepsProgress from '../../../../components/progress/Steps';
import { Box, Button, Typography } from '@mui/material';
import LogoutIcon from '../../../../../icons/LogoutIcon';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/auth-routes/routes';
import useLogout from '../../../../hooks/useLogout';
import { getChannelDetails } from '../../../../utils/getSetChannelDetails';
import { onboardingCompanyDetailsAtom } from '../../../onboarding/states';
import { useAtomValue } from 'jotai';

interface AddOrganisationHeaderProps {
  currentStep: number;
}

const AddOrganisationHeader: React.FC<AddOrganisationHeaderProps> = ({
  currentStep,
}) => {
  const navigate = useNavigate();
  const handleLogout = useLogout();
  const onboardingCompanyDetails = useAtomValue(onboardingCompanyDetailsAtom);
  const channelPartnerData = getChannelDetails();
  let logoUrl = onboardingCompanyDetails?.logo;
  try {
    if (logoUrl && typeof logoUrl === 'object') {
      logoUrl = URL.createObjectURL(logoUrl);
    }
  } catch (e) {
    console.log(e);
  }

  return (
    <Box
      css={css`
        border: 1px solid #e0e0e9;
        background: #fff;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      `}
    >
      <Button onClick={() => navigate(ROUTES.homePage)}>
        <img
          src={logoUrl as string}
          alt="logo"
          height={'auto'}
          css={css`
            max-width: 100px;
            max-height: 50px;
          `}
        />
      </Button>
      <StepsProgress
        maxWidth={'500px'}
        currentStep={currentStep}
        stepsInfo={[
          {
            stepNumber: 1,
            title: 'Basic info',
          },
          {
            stepNumber: 2,
            title: 'Billing details',
          },
          {
            stepNumber: 3,
            title: 'Choose Plan',
          },
        ]}
      />
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={1}
      >
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 14px;
            font-weight: 500;
          `}
        >
          {channelPartnerData?.phone}
        </Typography>
        <Button onClick={handleLogout}>
          <Typography
            css={css`
              color: #7a7a7a;
              font-size: 14px;
              font-weight: 500;
              position: relative;
              padding-left: 0px;
              &:after {
                content: '';
                background: #e0e0e9;
                height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
              }
            `}
          >
            Logout
          </Typography>
        </Button>
        <LogoutIcon />
      </Box>
    </Box>
  );
};

export default AddOrganisationHeader;

import I18N from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import es from './translations/es.json';
import ar from './translations/ar.json';
import hi from './translations/hi.json';
import de from './translations/de.json';
import fr from './translations/fr.json';

let i18N: typeof I18N;

export function getI18N() {
  if (!i18N) {
    throw new Error('Called before initialization');
  }
  return i18N;
}

export default function i18NInit() {
  const searchParams = new URLSearchParams(window.location.href.split('?')[1]);
  const languageCode = searchParams.get('languageCode') || 'en';
  I18N.use(initReactI18next).init({
    lng: languageCode,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      ar: {
        translation: ar,
      },
      hi: {
        translation: hi,
      },
      fr: {
        translation: fr,
      },
      de: {
        translation: de,
      },
    },
  });
  i18N = I18N;
}

import { ChannelPartnerConfigType } from '../types';

const KEY = 'CHANNEL_PARTNER_DETAILS';

const getChannelDetails = (): ChannelPartnerConfigType | undefined => {
  try {
    const data = localStorage.getItem(KEY);
    return data ? JSON.parse(data) : {};
  } catch (e) {
    console.error('Error in getting channel details', e);
    return;
  }
};

const setChannelDetails = async (data: ChannelPartnerConfigType) => {
  try {
    localStorage.setItem(KEY, JSON.stringify(data));
  } catch (e) {
    console.error('Error in setting channel details', e);
    return {};
  }
};

export { getChannelDetails, setChannelDetails };

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { createPopper, preventOverflow, flip, State } from '@popperjs/core';

interface DropdownProps {
  trigger: ReactNode;
  position?: 'top' | 'bottom';
  theme?: Record<string, string>;
  children: ReactNode | ReactNode[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

// In a utility library:
function assertIsNode(event: EventTarget | null): asserts event is Node {
  if (!event || !('nodeType' in event)) {
    throw new Error(`Node expected`);
  }
}

const Dropdown = forwardRef<
  { resize: (node: HTMLDivElement | null) => void },
  DropdownProps
>(
  (
    { children, theme, trigger, open, setOpen, position = 'bottom' },
    sizeRef
  ) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const outerContainerRef = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(sizeRef, () => {
      return {
        resize: (node) => {
          setContainer(node);
        },
      };
    }, []);

    useEffect(() => {
      const outerContainer = outerContainerRef.current;
      if (outerContainer) {
        const onMouseDown = (event: MouseEvent) => {
          assertIsNode(event.target);

          if (!outerContainer.contains(event.target)) {
            setOpen(false);
          }
        };

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            setOpen(false);
          }
        };

        document.addEventListener('mousedown', onMouseDown);
        document.addEventListener('keydown', onEscape);

        return () => {
          document.removeEventListener('mousedown', onMouseDown);
          document.removeEventListener('keydown', onEscape);
        };
      }
    }, [setOpen]);

    useLayoutEffect(() => {
      if (dropdownRef.current && container && open) {
        createPopper(container as Element, dropdownRef.current, {
          strategy: 'fixed',
          modifiers: [
            preventOverflow,
            flip,
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({ state }: { state: State }) => {
                state.styles.popper.width = `${state.rects.reference.width}px`;
              },
              effect({ state }: { state: State }) {
                state.elements.popper.style.width = `${
                  (state.elements.reference as HTMLElement).offsetWidth
                }px`;
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
            },
          ],
        });
      }
    }, [open, container]);

    return (
      <div
        css={css`
          position: relative;
          box-sizing: border-box;
          color: #2d2d2d;
        `}
        ref={outerContainerRef}
      >
        <div>{trigger}</div>
        {open && (
          <div
            css={css`
              position: fixed;
              z-index: 10000;
            `}
            tabIndex={0}
            ref={dropdownRef}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;

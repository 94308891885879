import { useAtomValue } from 'jotai';
import { walletConfigAtom } from '../modules/wallet/state';
import usePrevious from './usePrevious';
import useRefetchWalletCredits from './useRefetchWalletCredits';
import { useEffect, useRef } from 'react';

const useStripePaymentHook = () => {
  const walletConfig = useAtomValue(walletConfigAtom);
  const walletConfigRef = useRef(walletConfig); // Create a ref to track walletConfig
  const { refreshData } = useRefetchWalletCredits();
  const prevBalance = usePrevious(walletConfig.data?.balance);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the interval ID

  useEffect(() => {
    walletConfigRef.current = walletConfig;
  }, [walletConfig]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
    };
  }, []);

  const startPooling = (
    stripeTab: Window | null,
    retries: number,
    currentBalance: number,
    successCallback?: () => void,
    errorCallback?: () => void
  ) => {
    if (retries <= 0) {
      stripeTab?.close();

      return;
    }

    intervalRef.current = setTimeout(() => {
      refreshData();
      const latestWalletConfig = walletConfigRef.current;

      if (
        latestWalletConfig?.data?.balance &&
        prevBalance &&
        latestWalletConfig?.data?.balance > currentBalance
      ) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        stripeTab?.close(); // Close the tab when the balance increases
        successCallback?.();
      } else {
        startPooling(
          stripeTab,
          retries - 1,
          currentBalance,
          successCallback,
          errorCallback
        );
      }
    }, 3000);

    return intervalRef.current;
  };

  const makeStripePayment = ({
    currentBalance,
    amount,
    successCallback,
    errorCallback,
  }: {
    currentBalance: number;
    amount: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  }) => {
    if (walletConfig.data?.rechargeLink) {
      const rechargeLink = new URL(walletConfig.data.rechargeLink);
      rechargeLink.searchParams.set('__prefilled_amount', amount + '00');

      // Open Stripe payment link in a new tab
      const stripeTab = window.open(rechargeLink.toString());

      // Start polling to check if the payment is successful and then close the tab
      startPooling(
        stripeTab,
        480,
        currentBalance,
        successCallback,
        errorCallback
      );
    }
  };

  return { makeStripePayment };
};

export default useStripePaymentHook;

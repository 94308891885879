import { FC, SVGProps } from 'react';

const ExportIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  size = 24,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39144 8.98647H6.45844C4.42344 8.98647 2.77344 10.6365 2.77344 12.6715V17.5465C2.77344 19.5805 4.42344 21.2305 6.45844 21.2305H17.5884C19.6234 21.2305 21.2734 19.5805 21.2734 17.5465V12.6615C21.2734 10.6325 19.6284 8.98647 17.5994 8.98647L16.6564 8.98647"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0234 2.19337V14.2344"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.10938 5.12109L12.0244 2.19309L14.9404 5.12109"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ExportIcon;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';
interface StepProgressBarProps {
  stepNumber: number;
  title: string;
  isCompleted: boolean;
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({
  stepNumber,
  title,
  isCompleted,
}) => (
  <Box
    css={css`
      position: relative;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: baseline;
      z-index: 1;
      flex-direction: row;
      background-color: #fff;
      padding: 0 16px;
      gap: 6px;
    `}
  >
    <Box
      css={css`
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${isCompleted
          ? 'var(--bg-color-primary)'
          : '#E4E8EF'};
        border-radius: 50%;
        color: ${isCompleted ? '#fff' : '#7A7A7A'};
        z-index: 1;
        font-size: 14px;
      `}
      color={'primary'}
    >
      {stepNumber}
    </Box>
    <Box
      css={css`
        height: 30%;
        z-index: 0;
        color: ${isCompleted ? '#000' : '#7A7A7A'};
        text-align: center;
        font-size: 14px;
        font-weight: 500;
      `}
    >
      {title}
    </Box>
  </Box>
);

interface StepsProgressProps {
  stepsInfo: Array<{
    stepNumber: number;
    title: string;
    isCompleted?: boolean;
  }>;
  currentStep: number;
  maxWidth?: string;
}

const StepsProgress: React.FC<StepsProgressProps> = ({
  stepsInfo,
  maxWidth = '600px',
  currentStep,
}) => {
  const getProgressColor = (percentage: number) => {
    if (percentage === 0) {
      return 'var(--bg-color-secondary), var(--bg-color-secondary)';
    } else if (percentage === 1) {
      return '90deg, var(--bg-color-primary) 0 40%, var(--bg-color-secondary) 40% 90%';
    } else if (percentage === 2) {
      return '90deg, var(--bg-color-primary) 0 65%, var(--bg-color-secondary) 65% 90%';
    }
    return '90deg, var(--bg-color-primary) 0 100%';
  };

  return (
    <Box
      css={css`
        margin: auto;
        display: flex;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        gap: 0;
        max-width: ${maxWidth};
      `}
    >
      {stepsInfo.map((item, idx) => (
        <StepProgressBar
          isCompleted={currentStep >= idx}
          key={`steps_${idx}`}
          stepNumber={item.stepNumber}
          title={item.title}
        />
      ))}
      <Box
        css={css`
          position: absolute;
          top: 12px;
          left: 24px;
          height: 2px;
          width: 90%;
          background: linear-gradient(${getProgressColor(currentStep)});
          z-index: 0;
        `}
      />
    </Box>
  );
};

export default StepsProgress;

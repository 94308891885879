import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { APP_BACKGROUD_COLOR } from './app/constants/colors';

const ErrorRedColor = '#C21313';

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      default: APP_BACKGROUD_COLOR,
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: '#3F906D',
    },
    text: {
      primary: '#2d2d2d',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            border: '1px solid #fff',
            borderRadius: '8px',
            cursor: 'not-allowed',
          },
        },
        // Apply additional styles for the disabled state to the root
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#efefef',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Apply styles to the root element of the outlined input
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E0E0E9',
            borderRadius: '8px',
            color: ErrorRedColor,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: ErrorRedColor,
          },
          '& .MuiInputBase-input': {
            backgroundColor: '#FFF',
            color: '#2d2d2d',
          },
          '&.Mui-error .MuiInputBase-input': {
            color: ErrorRedColor,
          },
        },
        input: {
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '-moz-appearance': 'textfield',
          '&::placeholder': {
            fontSize: '14px',
            fontWeight: 400,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: ErrorRedColor,
            marginLeft: 0,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #E0E0E9',
              borderRadius: '8px',
            },
          },
          '& .MuiInputBase-input': {
            backgroundColor: '#FFF',
            color: '#2d2d2d',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontSize: '14px',
            fontWeight: 400,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input[type="number"]': {
            '-moz-appearance': 'textfield', // For Firefox
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
          },
          '&.Mui-error': {
            '& input': {
              color: '#C21313',
            },
            '& fieldset': {
              borderColor: 'green',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'green',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {},
      },
      variants: [
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            backgroundColor: '#d32f2f',
            color: '#FFF',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: red[700],
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #e0e0e9',
          padding: '20px',
          borderRadius: '12px',
          backgroundColor: '#fff',
        },
      },
    },
  },
});

export default theme;

import { get } from '../../../infra/rest';
import { CountriesOptionAPIData } from '../types';

export const getAddressDetails = async ({
  countryId,
  stateId,
}: {
  countryId?: string;
  stateId?: string;
}): Promise<CountriesOptionAPIData> => {
  const params = new URLSearchParams();
  if (countryId) {
    params.append('countryId', countryId);
  }

  if (stateId) {
    params.append('stateId', stateId);
  }

  const url = `/v1/internal/shipping-address${
    params.toString() ? `?${params.toString()}` : ''
  }`;

  return get<CountriesOptionAPIData>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};

import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { OrganisationsSortTermAtomType } from '../types';
import { OrgSortTypes } from './reducers/sort.reducers';
import { createPaginatedRequestAtom } from '../../../utils/atoms/request-atom';
import {
  OrgListCustomersProps,
  OrgListCustomersResponsePaginationArgs,
} from '../../home/types';

export const organisationsSearchTermAtom = atom<string>('');
export const organisationsTotalOrgs = atom<number>(0);

export const organisationsSortTermAtom =
  atomWithReset<OrganisationsSortTermAtomType>({
    key: OrgSortTypes.newest_first,
    sortFilter: 'dateCreated',
    direction: 'DESC',
  });

export const organisationsListAtom = createPaginatedRequestAtom<
  OrgListCustomersProps,
  OrgListCustomersResponsePaginationArgs
>();

import { StatusBadgeTypes } from '../../../components/badge';
import React from 'react';
import { OrgListCustomersProps } from '../../home/types';
import { ProflieAPIResponseType } from '../../orgProfile/types';

export type AddCreditModaLProps = {
  open: boolean;
  handleClose: () => void;
  style?: React.CSSProperties;
};

export type AssignCreditsModalProps = {
  open: boolean;
  handleClose: () => void;
  style?: React.CSSProperties;
  step: AssignCreditSteps;
  organisation?: ProflieAPIResponseType;
};

export type CreditCheckoutProps = {
  selectedOrg: OrgListCustomersProps;
  handleClose: () => void;
  setSelectedOrg: (orgName: OrgListCustomersProps) => void;
  setCurrentStep: (currentStep: AssignCreditSteps) => void;
};

export type SelectOrganisationProps = {
  selectedOrg: OrgListCustomersProps | undefined;
  setCurrentStep: (currentStep: AssignCreditSteps) => void;
  setSelectedOrg: (name: OrgListCustomersProps) => void;
};

export type WalletConfigType = {
  balance: number;
  currencyCode: string;
  customerRechargeOperationsAllowed: boolean;
  rechargeLink: string;
};

export enum AssignCreditSteps {
  SELECT_ORGANISATION = 'select_organisation',
  ASSIGN_CREDIT = 'assign_credit',
}

export type TransactionType = StatusBadgeTypes.CREDIT | StatusBadgeTypes.DEBIT;

export type ActionType =
  | 'WALLET_RECHARGE'
  | 'CUSTOMER_WALLET_RECHARGE'
  | 'SUBSCRIPTION';

export type StatusType =
  | StatusBadgeTypes.SUCCESS
  | StatusBadgeTypes.FAILURE
  | StatusBadgeTypes.PENDING;

export interface PaymentHistoryRowType {
  name: string;
  transactionType: TransactionType;
  action: ActionType;
  dateCreated: string;
  status: StatusType;
  amount: number;
}
export interface PaymentHistoryAPIResponse {
  data: PaymentHistoryRowType[];
  paginationOptions: {
    hasMoreLogs: boolean;
    nextLogDateCreated: number;
  };
  hasMoreLogs: boolean;
}

export interface PaymentHistoryAPIResponsePaginationArgs {
  hasMoreLogs: boolean;
  nextLogDateCreated: number;
}
export interface PaymentDatatype {
  orderId: string;
  apiKey: string;
}

export interface WalletRechargeType {
  paymentService: string;
  paymentData: PaymentDatatype;
}

export interface ClientWalletRechargeAPIResponse {
  success: boolean;
}

export interface ExportPaymentHistoryResponse {
  fileName: string;
  filePath: string;
}

import {
  PaymentHistoryAPIResponsePaginationArgs,
  PaymentHistoryRowType,
  WalletConfigType,
} from '../types';
import {
  createPaginatedRequestAtom,
  createRequestAtom,
} from '../../../utils/atoms/request-atom';

const walletConfigAtom = createRequestAtom<WalletConfigType>();

const paymentHistoryAtom = createPaginatedRequestAtom<
  PaymentHistoryRowType,
  PaymentHistoryAPIResponsePaginationArgs
>();

export { walletConfigAtom, paymentHistoryAtom };

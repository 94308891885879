import { FC, SVGProps } from 'react';

const HeaderWallet: FC<SVGProps<SVGSVGElement>> = ({
  color = '#2d2d2d',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.7618 2.5C16.6337 2.5 18.3307 4.14885 18.3307 6.94822V6.97927H14.8018C13.1596 6.98216 11.8292 8.27929 11.8263 9.88047C11.824 11.4853 13.1559 12.7882 14.8018 12.7903H18.3337V13.0453C18.3337 15.8446 16.6366 17.5 13.7648 17.5H6.23662C3.36403 17.5 1.66699 15.8446 1.66699 13.0453V6.94822C1.66699 4.14885 3.36403 2.5 6.23662 2.5H13.7618ZM10.3196 5.72909H5.61662C5.26699 5.73054 4.98329 6.00715 4.9818 6.34876C4.98033 6.69182 5.26477 6.97133 5.61662 6.97277H10.3255C10.6774 6.97133 10.9618 6.69182 10.9603 6.34804C10.9588 6.00498 10.6714 5.72765 10.3196 5.72909Z"
        fill="#3F906D"
      />
      <path
        opacity="0.4"
        d="M13.3652 10.2472C13.5394 11.0399 14.2344 11.5976 15.0278 11.5831H17.7361C18.0662 11.5831 18.334 11.3097 18.334 10.9717V8.86208C18.3333 8.52485 18.0662 8.25073 17.7361 8.25H14.9641C14.0615 8.2529 13.3326 9.00204 13.334 9.92523C13.334 10.0333 13.3446 10.1413 13.3652 10.2472Z"
        fill="#3F906D"
      />
      <path
        d="M15.0003 10.7499C15.4606 10.7499 15.8337 10.3768 15.8337 9.91658C15.8337 9.45635 15.4606 9.08325 15.0003 9.08325C14.5401 9.08325 14.167 9.45635 14.167 9.91658C14.167 10.3768 14.5401 10.7499 15.0003 10.7499Z"
        fill="#3F906D"
      />
    </svg>
  );
};

export default HeaderWallet;

import { post } from '../../../infra/rest';
import { AddEmailResponse } from '../typings';

export const addEmail = async (email: string) => {
  return post<AddEmailResponse>(
    `/v1/auth/email-add`,
    {
      body: JSON.stringify({
        email,
      }),
    },
    true
  );
};

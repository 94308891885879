/** @jsxImportSource @emotion/react */
import { FormEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { css, TextField } from '@mui/material';
import { rechargeWallet } from '../request';
import { AddCreditModaLProps } from '../types';
import useRazorPayPaymentHook from '../../../hooks/useRazorPayPaymentHook';
import useStripePaymentHook from '../../../hooks/useStripePaymentHook';
import CustomButton from '../../../components/button/CustomButton';
import { formatCurrency } from '../../../utils/currencyFormatter';
import { walletConfigAtom } from '../state';
import { useAtomValue } from 'jotai';
import InfoIcon from '@mui/icons-material/Info';
import SuccessModal from './PaymentSuccessModal';

const AddCreditModal = ({ open, handleClose, style }: AddCreditModaLProps) => {
  const [amount, setAmount] = useState('');
  const walletConfig = useAtomValue(walletConfigAtom);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [waitingOnStripePayment, setWaitingOnStripePayment] = useState(false);
  const { makeRazPayment } = useRazorPayPaymentHook();
  const { makeStripePayment } = useStripePaymentHook();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const integerAmount = parseFloat(amount);
    const rechargeWalletResponse = await rechargeWallet({
      amount: integerAmount,
    });
    if (rechargeWalletResponse?.paymentService === 'RAZORPAY') {
      const { orderId, apiKey } = rechargeWalletResponse.paymentData;
      makeRazPayment({
        apiKey,
        orderId,
        amount: integerAmount,
        successCallback: () => {
          setSuccessModalOpen(true);
        },
      });
    } else {
      setWaitingOnStripePayment(true);
      makeStripePayment({
        currentBalance: walletConfig?.data?.balance || 0,
        amount: integerAmount + '',
        successCallback: () => {
          setSuccessModalOpen(true);
        },
        errorCallback: () => setWaitingOnStripePayment(false),
      });
    }
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableRestoreFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 350,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          ...style,
        }}
      >
        <Typography
          fontWeight={600}
          py={1}
          px={2}
          borderBottom={'1px solid #e0e0e0'}
        >
          Wallet Recharge
        </Typography>
        <Box component="form" onSubmit={handleSubmit} p={2}>
          <Typography variant="h6" fontWeight={600} fontSize={'1rem'} mb={1}>
            Amount
          </Typography>
          <TextField
            required
            autoFocus
            value={amount}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                setAmount(e.target.value);
              }
            }}
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 2,
            }}
            InputProps={{
              style: {
                width: '100%',
                borderRadius: 2,
              },
            }}
          />
          <Typography
            variant={'caption'}
            css={css`
              display: flex;
              align-items: center;
              color: #555;
              margin-top: 10px;
              column-gap: 4px;
            `}
          >
            <InfoIcon
              css={css`
                color: #000;
                height: 12px;
                width: 12px;
              `}
            />
            Wallet recharge is non-refundable
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography fontWeight={600}>Total</Typography>
            <Typography fontWeight={600}>
              {formatCurrency(
                parseFloat(amount || '0'),
                walletConfig?.data?.currencyCode
              )}
            </Typography>
          </Box>
          <CustomButton
            loading={waitingOnStripePayment}
            type="submit"
            variant="contained"
            sx={{
              width: '100%',
              borderRadius: 2,
              mt: 2,
            }}
          >
            Recharge Now
          </CustomButton>
          <SuccessModal
            open={successModalOpen}
            handleClose={handleSuccessModalClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCreditModal;

import sha256 from 'crypto-js/sha256';
import {
  generateNonce,
  resendOtpWithNumber,
  getOtpWithNumber,
} from './get-otp';
import { OTPServices } from '../typings';

enum GenerateOTPUseCaseConstant {
  productType = 'DOUBLETICK',
}

interface GenerateOtpUseCaseProps {
  service: string;
  deviceToken: string;
  countryCode: string;
  isResend: boolean;
  phoneNumber: string;
}

export const generateOtpUseCase = async ({
  service = OTPServices.WHATSAPP,
  deviceToken,
  countryCode,
  isResend,
  phoneNumber,
}: GenerateOtpUseCaseProps) => {
  const currentTimeStamp = new Date().toISOString();

  const response = await generateNonce({
    currentTimeStamp,
    deviceToken,
    service,
    productType: GenerateOTPUseCaseConstant.productType,
    countryCode,
    phoneNumber,
  });
  const nonce = sha256(response.nonce + currentTimeStamp).toString();
  if (isResend) {
    return await resendOtpWithNumber({
      phoneNumber,
      countryCode,
      deviceToken,
      service,
      productType: GenerateOTPUseCaseConstant.productType,
      nonce,
    });
  }
  return await getOtpWithNumber({
    phoneNumber,
    countryCode,
    deviceToken,
    service,
    productType: GenerateOTPUseCaseConstant.productType,
    nonce,
  });
};

/** @jsxImportSource @emotion/react */
import { Button, Card, Grid, Typography, css } from '@mui/material';
import React from 'react';

const EntContactUsCard = () => {
  const handleOnGetHelpClick = () => {
    let encodedMessage = encodeURIComponent('Regarding enterprise plan');
    window.open(`https://wa.me/917977814605?text=${encodedMessage}`, '_blank');
  };
  const handleEmailClick = () => {
    window.location.href = 'mailto:sales@doubletick.io';
  };
  return (
    <Card
      css={css`
        margin-top: 20px;
        border: 1px solid #e0e0e0;
        background: #fcf8f4;
      `}
    >
      <Grid container>
        <Grid item>
          <Typography
            css={css`
              color: #2d2d2d;
              font-size: 14px;
              font-weight: 600;
            `}
          >
            Contact us to purchase enterprise plan
          </Typography>
          <Typography
            css={css`
              color: #585858;
              font-size: 14px;
              font-weight: 400;
            `}
          >
            Talk to our sales team for more info
          </Typography>
        </Grid>
        <Grid item xs></Grid>
        <Grid item>
          <Button
            onClick={handleEmailClick}
            css={css`
              color: #3f906d;
              font-size: 15px;
              font-weight: 600;
            `}
          >
            sales@doubletick.io
          </Button>
        </Grid>

        <Grid item>
          <Button
            onClick={handleOnGetHelpClick}
            css={css`
              color: #3f906d;
              font-size: 15px;
              font-weight: 600;
            `}
          >
            +91 7977 814 605
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default EntContactUsCard;

import { useNavigate } from 'react-router-dom';
import { firebaseAuth } from '../infra/firebase/init';
import { ROUTES } from '../routes/auth-routes/routes';

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    firebaseAuth.signOut();
    window.localStorage.clear();
    navigate({ pathname: ROUTES.homePage }, { replace: true });
  };
  return handleLogout;
};

export default useLogout;

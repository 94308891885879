import requestConfig from '../../config/request';
import { get } from '../infra/rest';
import { Geo } from '../types';

let cachePromise: Promise<Geo>;
let cacheData: Geo;

interface GetGeoIpProps {
  signal?: AbortSignal;
  refresh?: boolean;
}

export const getGeoIp = async ({
  signal,
  refresh,
}: GetGeoIpProps): Promise<Geo> => {
  if (!refresh) {
    if (cacheData) {
      return cacheData;
    }

    if (cachePromise) {
      return cachePromise;
    }

    cachePromise = get<Geo>(
      `${requestConfig.qsApiUrl}/v1/config/ip-location`,
      { signal },
      false,
      undefined,
      true
    ).then((data) => {
      cacheData = data;
      return data;
    });
    return cachePromise;
  }

  return get<Geo>(
    `${requestConfig.qsApiUrl}/v1/config/ip-location`,
    { signal },
    false,
    undefined,
    true
  );
};

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Route, Routes } from 'react-router-dom';
import { getUnprotectedRoutesV1 } from './v1';
import { Typography } from '@mui/material';
import { getI18N } from '../../../i18N';

export default function AppUnProtectedRoutes() {
  const { t } = getI18N();
  return (
    <Routes>
      {getUnprotectedRoutesV1()}
      <Route
        path="*"
        element={
          <div
            css={css`
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 16px;
            `}
          >
            <Typography
              css={css`
                font-size: 16px;
                font-weight: 400;
              `}
            >
              {t('nothing_to_see_here')}
            </Typography>
          </div>
        }
      />
    </Routes>
  );
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, Typography } from '@mui/material';

const OnboardingFooter = () => {
  return (
    <Typography
      css={css`
        color: #959595;
        font-size: 16px;
        margin-top: 34px;
      `}
    >
      By proceeding you will agree to our{' '}
      <Link href={'https://www.doubletick.io/terms-and-condition'}>
        terms of service
      </Link>{' '}
      and{' '}
      <Link href={'https://www.doubletick.io/privacy-policy'}>
        privacy policy
      </Link>
    </Typography>
  );
};

export default OnboardingFooter;

/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { FC } from 'react';
import { getI18N } from '../../../i18N';
import { Button, Typography } from '@mui/material';
import { TEXT_COLOR_LIGHT } from '../../constants/colors';

interface ErrorContainerProps {
  error?: string;
  customCss?: SerializedStyles;
  showBack?: boolean;
}

const ErrorContainer: FC<ErrorContainerProps> = ({
  error,
  customCss,
  showBack = true,
}) => {
  const { t } = getI18N();
  return (
    <div
      css={css(
        css`
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 12px;
        `,
        customCss
      )}
    >
      <div
        css={css`
          font-family: 'Avenir Next';
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          text-align: center;
          color: ${TEXT_COLOR_LIGHT};
        `}
      >
        <Typography
          css={css`
            font-size: 74px;
            font-weight: 700;
            word-wrap: break-word;
            word-break: break-word;
          `}
        >
          {t('error_messsage')}
        </Typography>
        <Typography
          css={css`
            font-size: 18px;
            font-weight: 500;
            word-wrap: break-word;
            word-break: break-word;
          `}
        >
          {error ?? t('an_error_occured')}
        </Typography>
      </div>
      <Button
        variant="text"
        onClick={() => window.location.reload()}
        css={css`
          background: none;
          box-shadow: none;
          outline: none;
        `}
      >
        <Typography
          css={css`
            font-size: 16px;
            font-weight: 400;
            word-wrap: break-word;
            word-break: break-word;
          `}
        >
          {t('reload_page')}
        </Typography>
      </Button>
    </div>
  );
};

export default ErrorContainer;

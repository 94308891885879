import React from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';
import SideBarDrawer from '../modules/sidebar';

const WithSideBar = ({ children }: { children: EmotionJSX.Element }) => {
  return (
    <Box ml={'50px'}>
      <SideBarDrawer />
      {children}
    </Box>
  );
};

export default WithSideBar;

/** @jsxImportSource @emotion/react */
import { Box, Typography, css, Avatar, Tooltip } from '@mui/material';
import React from 'react';
import { stringAvatar } from '../../../utils';

interface ProfileCellProps {
  name: string;
  profileURL?: string;
  height?: string;
  width?: string;
  secondaryText?: string;
  isInitialsAvatar?: boolean;
  hideName?: boolean;
  maxLetters?: number;
}

const ProfileCell: React.FC<ProfileCellProps> = ({
  name,
  profileURL,
  secondaryText,
  isInitialsAvatar = false,
  height = '40px',
  width = '40px',
  hideName,
  maxLetters = 50,
}) => {
  if (!profileURL || isInitialsAvatar) {
    return (
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <Avatar
          variant={'rounded'}
          {...stringAvatar(name)}
          css={css(`height:${height}; width:${width}`)}
        />
        <Box>
          {!hideName ? (
            <Tooltip title={name} placement="top">
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `${maxLetters}ch`,
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </Typography>
            </Tooltip>
          ) : null}
          {secondaryText && (
            <Typography
              css={css`
                font-size: 14px;
                font-weight: 400;
                color: #7a7a7a;
              `}
            >
              {secondaryText}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box display={'flex'} gap={1} alignItems={'center'}>
      <img
        height={'40px'}
        width={'40px'}
        css={css`
          border-radius: 8px;
        `}
        src={profileURL}
        alt={name}
      />
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Tooltip title={name} placement="top">
          <Typography>{name}</Typography>
        </Tooltip>
        {secondaryText && (
          <Typography
            css={css`
              font-size: 14px;
              font-weight: 400;
              color: #7a7a7a;
            `}
          >
            {secondaryText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProfileCell;

/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  css,
} from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import HomeIconOutlined from '../../../icons/HomeIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import OrgIconOutlined from '../../../icons/OrgIcon';
import { ROUTES } from '../../routes/auth-routes/routes';
import LogoutIconSideBar from '../../../icons/LogoutIconSideBar';
import useLogout from '../../hooks/useLogout';

type MenuItem = {
  text: string;
  link: string;
  icon: (color: string) => React.ReactNode;
};
const menuItems: MenuItem[] = [
  {
    text: 'Home',
    link: ROUTES.homePage,
    icon: (color) => <HomeIconOutlined fill={color} />,
  },
  {
    text: 'Organisations',
    link: ROUTES.organisationPage,
    icon: (color) => <OrgIconOutlined fill={color} />,
  },
  {
    text: 'Wallet',
    link: ROUTES.walletPage,
    icon: (color) => <AccountBalanceWalletOutlinedIcon style={{ color }} />,
  },
];

const SideBarDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedItemLink, setSelectedItemLink] = useState<string | null>(null);
  const location = useLocation();
  const SELECTED_COLOR = '#3F906D';
  const handleLogout = useLogout();

  // Function to handle item selection
  const handleSelectItem = (link: string) => {
    setSelectedItemLink(link);
    navigate(link);
  };

  // // Effect to update selection based on URL
  useEffect(() => {
    const foundItem = menuItems.find((item) => location.pathname === item.link);
    if (foundItem) {
      setSelectedItemLink(foundItem.link);
    } else {
      // Optionally set to the last item or keep the current selection
      setSelectedItemLink(menuItems[menuItems.length - 1].link);
    }
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'flex-start',
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 100,
      }}
    >
      <Drawer
        anchor="left"
        open
        onClose={handleDrawerClose}
        variant="persistent"
        PaperProps={{
          sx: {
            backgroundImage: 'linear-gradient(90deg, #FFF 0%, #F2F2F2 100%)',
            width: open ? 240 : 50,
            transition: 'width 0.5s',
            overflowX: 'hidden',
            zIndex: 10,
            top: '40px',
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: 240,
            marginTop: '20px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <List
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
            css={css`
              padding: 0;
              height: 100%;
            `}
          >
            {menuItems.map((item, index) => (
              <ListItem
                button
                onClick={() => handleSelectItem(item.link)}
                key={index + item.link}
                css={css(
                  `
                  padding: 8.5px 0;
                `,
                  selectedItemLink === item.link &&
                    `background-color: #00000006; color: ${SELECTED_COLOR}`
                )}
              >
                <ListItemIcon>
                  <Box
                    width={'50px'}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    {item.icon(
                      selectedItemLink === item.link
                        ? SELECTED_COLOR
                        : '#7a7a7a'
                    )}
                  </Box>
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>

          <List
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
            sx={{
              marginTop: 'auto',
              marginBottom: '65px',
            }}
          >
            <ListItem
              button
              onClick={handleLogout}
              css={css`
                padding: 8.5px 0;
              `}
            >
              <ListItemIcon>
                <Box width={'50px'} display={'flex'} justifyContent={'center'}>
                  <LogoutIconSideBar />
                </Box>
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBarDrawer;

import { post, put } from '../../../infra/rest';
import {
  CreateOrgCompanyDetailsResponseType,
  NewUserBillingDetailsType,
  NewUserBillingDetailsTypeResponseType,
  SaveOrgCompanyRequestDetailsType,
} from '../types';

export const postNewOrgDetails = async (
  partnerId: string,
  payload: SaveOrgCompanyRequestDetailsType
): Promise<CreateOrgCompanyDetailsResponseType> => {
  return post<CreateOrgCompanyDetailsResponseType>(
    `/v1/channel-partner/${partnerId}/customer`,
    {
      body: JSON.stringify(payload),
    },
    true
  );
};

export const putNewOrgDetails = async (
  partnerId: string,
  partnerCustomerId: string,
  payload: NewUserBillingDetailsType
): Promise<NewUserBillingDetailsTypeResponseType> => {
  return put<NewUserBillingDetailsTypeResponseType>(
    `/v1/channel-partner/${partnerId}/customer/${partnerCustomerId}/billing`,
    {
      body: JSON.stringify(payload),
    },
    true
  );
};

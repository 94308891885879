/** @jsxImportSource @emotion/react */
import { Box, Typography, Modal, Button } from '@mui/material';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

interface SuccessModalProps {
  open: boolean;
  handleClose: () => void;
}

const SuccessModal = ({ open, handleClose }: SuccessModalProps) => {
  const [animationData, setAnimationData] = useState<unknown>(null);

  useEffect(() => {
    if (open && !animationData) {
      import('../../../../assets/lottie/CheckAnimation.json').then((data) => {
        setAnimationData(data.default);
      });
    }
  }, [open, animationData]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: 2,
            padding: 2,
          }}
        >
          <div
            css={css`
              height: 120px;
              width: 120px;
              margin-bottom: 8px;
              background-color: rgba(46, 125, 50, 0.04);
              border-radius: 50%;
              padding: 0;
            `}
          >
            {animationData ? (
              <Lottie loop={false} animationData={animationData} />
            ) : null}
          </div>
          <Typography
            id="success-modal-description"
            sx={{
              fontSize: 18,
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Congratulations! Your recharge is completed.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            padding: 2,
            borderTop: '1px solid #e0e0e0',
          }}
        >
          <Button
            onClick={handleClose}
            css={css`
              border-radius: 4px;
            `}
            variant="contained"
          >
            Okay
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;

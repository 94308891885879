import { ActionType } from '../modules/wallet/types';

export const getDescriptionFromAction = (action: ActionType) => {
  switch (action) {
    case 'WALLET_RECHARGE':
      return 'Wallet recharge';
    case 'CUSTOMER_WALLET_RECHARGE':
      return 'Customer wallet recharge';
    case 'SUBSCRIPTION':
      return 'Subscription';
    default:
      return 'Unknown';
  }
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;
  let color = '#';
  for (i = 0; i < string.length; i += 1)
    hash = string.charCodeAt(i) + ((hash << 4) - hash);
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}
function getTextColor(backgroundColor: string) {
  // Convert hex color to RGB
  const r = parseInt(backgroundColor.slice(1, 3), 16);
  const g = parseInt(backgroundColor.slice(3, 5), 16);
  const b = parseInt(backgroundColor.slice(5, 7), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

export function stringAvatar(name: string) {
  if (!name) {
    return {
      sx: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        width: 40,
        height: 40,
      },
      children: 'NA',
    };
  }
  const [firstName, lastName] = name.split(' ');
  let symbol = (firstName ?? '').substring(0, 1);
  if (lastName) {
    symbol += (lastName ?? '').substring(0, 1);
  }
  const bgColor = stringToColor(name);
  const textColor = getTextColor(bgColor);
  return {
    sx: {
      backgroundColor: bgColor,
      color: textColor,
      width: 40,
      height: 40,
    },
    children: `${symbol}`,
  };
}

export function calculateDateDifference(futureDate: string) {
  // Get the current date and time
  const currentDate = new Date();

  // Create a Date object from the passed future date
  const future = new Date(futureDate);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = future.getTime() - currentDate.getTime();

  if (differenceInMilliseconds <= 0) {
    return 'The date is not in the future.';
  }

  // Convert the difference to days, hours, minutes, and seconds
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);

  // Return the difference in a human-readable format
  return differenceInDays;
}

export function formatToLocalDate(isoDateString: string): string {
  // Create a Date object from the ISO date string
  const date = new Date(isoDateString);

  // Format the date to a readable local string
  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  return formattedDate;
}

export const handleCopyToClipBoard = (
  text: string,
  onSuccessCB: () => void,
  onFailCB: () => void
) => {
  if (text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        onSuccessCB?.();
      })
      .catch((err) => {
        onFailCB?.();
      });
  }
};

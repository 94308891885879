// "2024-08-27T10:06:11.926Z" into a format like "13th July 2023"
const formatDateWithIntl = (dateString: string): string => {
  const date = new Date(dateString);

  // Ensure the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  // Get the day of the month
  const day: number = date.getUTCDate();

  // Use Intl.DateTimeFormat to get the month and year in the desired format
  const monthYear: string = new Intl.DateTimeFormat('en-GB', {
    month: 'long',
    year: 'numeric',
  }).format(date);

  // Function to add the correct suffix to the day
  const getDayWithSuffix = (day: number): string => {
    if (day > 3 && day < 21) return `${day}th`; // For '11th', '12th', '13th', etc.
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  // Return the formatted string
  return `${getDayWithSuffix(day)} ${monthYear}`;
};

export default formatDateWithIntl;

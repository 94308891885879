import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { LoginStep, SigninStep, UserSignupDetails } from '../typings';
import {
  getCountryFromCountryCode,
  getCountryCodeFromLocalStorage,
} from '../../../utils/countries-mapper';

export const phoneNumberAtom = atomWithReset({
  country: getCountryFromCountryCode(getCountryCodeFromLocalStorage())!,
  phone: '',
});

export const errorAtom = atom('');

export const verifyOtpTokenAtom = atom('');

export const userSignupDetailsAtom = atomWithReset<UserSignupDetails>({
  firstName: '',
  lastName: '',
  orgName: '',
  designation: '',
  websiteUrl: 'https://',
  isWebsiteRequired: true,
});

export const LoginStepAtom = atom<LoginStep>(LoginStep.PHONE);
export const signInStepAtom = atom<SigninStep>(SigninStep.CREATE_ACCOUNT);

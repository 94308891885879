import { atom } from 'jotai';
import { OnboardingBillingDetails, OnboardingCompanyDetails } from '../types';
import {
  getCountryFromCountryCode,
  getCountryCodeFromLocalStorage,
} from '../../../utils/countries-mapper';

export const onboardingCompanyDetailsAtom = atom<OnboardingCompanyDetails>({
  name: '',
  domain: '',
  supportNumber: '',
  logo: null,
  savedCountry: getCountryFromCountryCode(
    getCountryCodeFromLocalStorage() ?? 'IN'
  )!,
});

export const onboardingBillingDetailsAtom = atom<OnboardingBillingDetails>({
  countryCode: 'IN',
  address: '',
  state: '',
  city: '',
  pinCode: '',
  email: '',
  billingTaxNumber: '',
  fullName: '',
});

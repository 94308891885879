import requestConfig from '../../../../config/request';
import { get, post } from '../../../infra/rest';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import { ProflieAPIResponseType, WabaListAPIResponseType } from '../types';

export const getProflieAPI = async ({
  orgId,
}: {
  orgId: string;
}): Promise<ProflieAPIResponseType> => {
  const partnerId = getChannelDetails()?.id;
  if (!partnerId) {
    throw new Error('Invalid partner ID');
  }
  return get<ProflieAPIResponseType>(
    `/v1/channel-partner/${partnerId}/customer/${orgId}/details`,
    {
      useDefaultHeaders: false,
    },
    true
  );
};

let controller = new AbortController();

export const getProflieWabaListAPI = async ({
  orgId,
  searchTerm,
}: {
  orgId: string;
  searchTerm: string;
}): Promise<WabaListAPIResponseType> => {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  const partnerId = getChannelDetails()?.id;
  if (!partnerId) {
    throw new Error('Invalid partner ID');
  }
  const url = new URL(
    `/v1/channel-partner/${partnerId}/customer/${orgId}/waba/list`,
    requestConfig.baseUrl
  );
  url.searchParams.append('searchFilter', searchTerm);

  return post<WabaListAPIResponseType>(
    url.toString(),
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    true,
    false,
    false,
    controller.signal
  );
};

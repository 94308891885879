/** @jsxImportSource @emotion/react */
import {
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  Fragment,
} from 'react';
import { css } from '@emotion/react';
import { InputBase } from '@mui/material';

type OTPInputProps = {
  value: string;
  onChange(value: string): void;
  size?: number;
  validationPattern?: RegExp;
  showDashes?: boolean;
};

const OTPInput = ({
  value,
  onChange,
  size = 6,
  validationPattern = /[0-9]{1}/,
  showDashes,
}: OTPInputProps) => {
  const arr = new Array(size).fill('-');
  const inputRefs = useRef<Array<HTMLInputElement | null>>(
    Array.from({ length: 6 }, () => null)
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const val = e.target.value;
      if (!validationPattern.test(val) && val === '') return;

      const newValue = [...value];
      newValue[index] = val;
      onChange(newValue.join('').slice(0, 6));

      if (val) {
        inputRefs.current[index + 1]?.focus();
      }
    },
    [value, onChange, validationPattern]
  );

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const current = e.currentTarget;
    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      let newOtpValue = [...value];
      newOtpValue[index] = '';
      onChange(newOtpValue.join(''));
      const prev = current.previousElementSibling as HTMLInputElement | null;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
      return;
    }

    if (e.key === 'ArrowRight') {
      const prev = current.nextSibling as HTMLInputElement | null;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
      return;
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.clipboardData.getData('text').substring(0, size);
    onChange(val);
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <Fragment>
      {showDashes ? (
        <div>
          {arr.map((_, index) => (
            <input
              key={index}
              css={css`
                border: none;
                border-bottom: 2px solid #2d2d2d;
                width: 30px;
                margin-right: 5px;
                padding: 5px;
                text-align: center;
                font-size: 16px;

                :focus-within {
                  outline: none;
                  border: none;
                  border-bottom: 2px solid green;
                }
              `}
              autoFocus
              value={value.at(index) ?? ''}
              onChange={(e) => handleInputChange(e, index)}
              onKeyUp={(e) => handleKeyUp(e, index)}
              onPaste={handlePaste}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
      ) : (
        <InputBase
          autoFocus={true}
          fullWidth
          sx={{
            marginTop: '16px',
            padding: '6px 12px',
            outline: 'none',
            borderRadius: '4px',
            border: '1px solid rgb(211, 211, 211)',
            '.MuiInput-root, .MuiInput-root': {
              '&::before': {
                border: 'none',
              },
              '&::after': {
                border: 'none',
              },
              '&:hover:not(.Mui-disabled)::before': {
                border: 'none',
              },
              'input::placeholder, textArea::placeholder': {
                color: '#7B7B7B',
              },
            },
          }}
          placeholder={'6 digit OTP code'}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </Fragment>
  );
};

export default OTPInput;

/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import BackButton from '../../components/BackButton';
import BillingDetails from './components/BillingDetails';
import BasicInfoInput from './components/BasicInfoInput';
import AddOrganisationHeader from './components/header';
import PlanSetup from './components/PlanSetup';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/auth-routes/routes';
import PlanBreakdown from './components/PlanBreakdown';

type AddOrgSteps = 'basic_info' | 'billing_details' | 'plan_setup';

const AddOrganisation = () => {
  const [currentStep, setCurrentStep] = useState<AddOrgSteps>('basic_info'); //add_credits
  const [createdOrgId, setCreatedOrgId] = useState<string | null>(null);
  const navigate = useNavigate();

  const renderStep = () => {
    if (currentStep === 'basic_info') {
      return (
        <Box width={'600px'} margin={'auto'}>
          <BackButton onClick={() => navigate(ROUTES.organisationPage)} />
          <BasicInfoInput
            setCreatedOrgId={(orgId: string) => setCreatedOrgId(orgId)}
            handleNext={() => setCurrentStep('billing_details')}
          />
        </Box>
      );
    }
    if (currentStep === 'billing_details') {
      return (
        <Box width={'600px'} margin={'auto'}>
          <BackButton onClick={() => setCurrentStep('basic_info')} />
          <BillingDetails
            handleNext={() => setCurrentStep('plan_setup')}
            createdOrgId={createdOrgId!}
          />
        </Box>
      );
    }
    if (currentStep === 'plan_setup') {
      return (
        <>
          <BackButton onClick={() => setCurrentStep('billing_details')} />
          <PlanSetup />
        </>
      );
    }
  };

  const progress = useMemo(() => {
    // set progress based on current step
    if (currentStep === 'basic_info') {
      return 0;
    }
    if (currentStep === 'billing_details') {
      return 1;
    }
    if (currentStep === 'plan_setup') {
      return 2;
    }
    return 3;
  }, [currentStep]);

  if (currentStep === 'plan_setup') {
    return (
      <Box bgcolor={'var(--Neutral-Background)'}>
        <AddOrganisationHeader currentStep={progress} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7} display={'flex'} justifyContent={'center'}>
            <Box mt={'60px'} width={'90%'}>
              {renderStep()}
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <PlanBreakdown createdOrgId={createdOrgId ?? ''} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box bgcolor={'var(--Neutral-Background)'}>
      <AddOrganisationHeader currentStep={progress} />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={'flex'}
          margin={'0 auto'}
          height={'100vh'}
          justifyContent={'center'}
        >
          <Box mt={'60px'}>{renderStep()}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddOrganisation;

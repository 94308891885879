/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ArrowRight } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import appConfig from '../../../../config/app';
import Loader from '../../../components/loader';
import useNotifications from '../../../hooks/useNotifications';
import APIError from '../../../infra/rest/errors/api-error';
import PhoneNumberComponent from '../otp-screen/components/phone-number';
import { generateOtpUseCase } from '../request/generate-otp-use-case';
import validateAndTruncatePhoneNumber from '../request/validate-phone';
import { LoginStepAtom, phoneNumberAtom } from '../state';
import { OTPServices, LoginStep } from '../typings';
import OnboardingFooter from './OnboardingFooter';
import OnboardingHeader from './OnboardingHeader';

const LoginComponent = () => {
  const setLoginStep = useSetAtom(LoginStepAtom);
  const { country, phone } = useAtomValue(phoneNumberAtom);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();
  const [isValidPhone, setIsValidPhone] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleLogin = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setIsValidPhone(true);
      setLoading(true);
      const phoneNumber = validateAndTruncatePhoneNumber({
        phone,
        countryCode: country.countryCode,
        countrySymbol: country.symbol,
      });
      const token = await recaptchaRef?.current?.executeAsync();
      if (!token) {
        addNotification({
          message:
            'Something went wrong while loading recaptcha, Please try again',
          type: 'error',
        });
        return;
      }

      await generateOtpUseCase({
        service: OTPServices.SMS,
        deviceToken: token,
        countryCode: country.countryCode,
        isResend: false,
        phoneNumber: phoneNumber,
      });
      setLoginStep(LoginStep.OTP);
    } catch (error) {
      if ((error as APIError).extraData?.errorCode === '1001') {
        addNotification({
          message: 'Please correct the date and time of your device',
          type: 'error',
        });
      } else {
        addNotification({
          message:
            (error as Error)?.message || 'An error occurred. Please try again',
          type: 'error',
        });
      }
    } finally {
      recaptchaRef.current?.reset();
      setLoading(false);
    }
  }, [
    loading,
    phone,
    country.countryCode,
    country.symbol,
    setLoginStep,
    addNotification,
  ]);

  return (
    <>
      <OnboardingHeader headerTitle="Get started" />
      <PhoneNumberComponent
        componentMarginTop={35}
        onSubmit={handleLogin}
        setIsValidPhone={setIsValidPhone}
      />
      <Button
        css={css`
          margin: auto;
          margin-top: 14px;
          width: 100%;
          padding: 10px 12px;
          display: flex;
          background-color: rgb(47, 166, 113);
        `}
        disabled={!isValidPhone}
        variant="contained"
        onClick={!loading ? handleLogin : () => {}}
      >
        {loading ? (
          <Loader size="24px" loaderProps={{ sx: { color: 'white' } }} />
        ) : (
          <>
            <Typography
              css={css`
                font-weight: 700px;
                margin-right: 6px;
              `}
            >
              Login
            </Typography>
            <ArrowRight height={'24px'} width={'30px'} />
          </>
        )}
      </Button>
      <ReCAPTCHA
        sitekey={appConfig.recaptchaSiteKey}
        size="invisible"
        ref={recaptchaRef}
      />
      <OnboardingFooter />
    </>
  );
};

export default LoginComponent;

import { ERROR_COLOR, GREEN } from '../types';

export const TEXT_COLOR_LIGHT = '#8484A8';
export const APP_BACKGROUD_COLOR = '#FBFBFC';

interface ButtonBgColorTypes {
  SUCCESS_BG_COLOR: string;
  WARNING_BG_COLOR: string;
}

export const buttonBgColor: ButtonBgColorTypes = {
  SUCCESS_BG_COLOR: `${GREEN}`,
  WARNING_BG_COLOR: `${ERROR_COLOR}`,
};

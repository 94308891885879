/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Box, Card, Grid, Typography } from '@mui/material';
import TwoHumanIcon from '../../../../../icons/TwoHumanIcon';
import { useAtomValue } from 'jotai';
import { orgProfileAtom } from '../../states';

const AgentSeats = () => {
  const orgProfileValue = useAtomValue(orgProfileAtom);

  return (
    <Box>
      <Card>
        <Grid container>
          <Grid item xs>
            <Typography
              css={css`
                color: #2d2d2d;
                font-size: 16px;
                font-weight: 500;
              `}
            >
              Agent seats
            </Typography>
            <Typography
              css={css`
                color: #7a7a7a;
                font-size: 14px;
                font-weight: 600;
              `}
            >
              Edit the number of users for this organisation
            </Typography>
          </Grid>
          <Grid item xs>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box>
                <Typography
                  css={css`
                    color: #2d2d2d;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 600;
                  `}
                >
                  Included in the{' '}
                  {orgProfileValue?.data?.subscribedPlan?.displayName}
                </Typography>
                <Typography
                  css={css`
                    color: #585858;
                    text-align: left;
                    font-size: 12px;
                    font-weight: 500;
                  `}
                >
                  Agent seats that you already get in the{' '}
                  {orgProfileValue?.data?.subscribedPlan?.displayName}
                </Typography>
              </Box>
              <Box>
                <Typography
                  css={css`
                    color: #2d2d2d;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                  `}
                >
                  <TwoHumanIcon />{' '}
                  {orgProfileValue?.data?.subscribedPlan?.numUsers} agents
                </Typography>
              </Box>
            </Box>
            {/* <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={'24px'}
            >
              <Box>
                <Typography
                  css={css`
                    color: #2d2d2d;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 600;
                  `}
                >
                  Additional agent seats
                </Typography>
              </Box>
              <Box>
                <Typography
                  css={css`
                    color: #2d2d2d;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                  `}
                >
                  <TwoHumanIcon /> 5
                </Typography>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default AgentSeats;

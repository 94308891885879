import { ChannelPartnerConfigType } from '../../../types';
import { Access } from '../../user-profile/v1/typings';

export interface VerifyOtpResponse {
  token: string;
  user: {
    countryCode: string;
    isOnWhatsapp: boolean;
    phone: string;
    isNewUser?: boolean;
  };
  channelPartner: ChannelPartnerConfigType;
}

export interface Organization {
  orgId: string;
  name: string;
  domain: string | null;
  pictureUrl: string | null;
  isIntegrationEnabled: boolean;
  inviteId?: string | null;
  isDemo?: boolean;
  isOwner: boolean;
  wabaPhoneNumber?: number;
}

export interface UserSignupDetails {
  firstName: string;
  lastName: string;
  orgName: string;
  designation: string;
  websiteUrl?: string;
  email?: string;
  isEmailVerified?: boolean;
  isWebsiteRequired: boolean;
}

export interface UserEmail {
  email: string;
  isVerified: boolean;
}

export interface AddEmailResponse {
  emailCode: string;
}

export interface VerifyEmailResponse {
  orgId: string;
}

export interface VerifyValidWhatsapp {
  phone: string;
  isValid: boolean;
}

export interface PhoneNumber {
  countryCode: string;
  countrySymbol: string;
  countryId: string;
  phone: string;
}

export enum UserAction {
  SEND_AND_RECEIVE_MESSAGES = 'sendAndReceiveMessages',
  SEND_AND_RECEIVE_PRODUCTS = 'sendAndReceiveProducts',
}

export interface ReceiveTestMessage {
  message: string;
  wabaPhoneNumber: string;
}

export enum OTPServices {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export enum TechProviderProgress {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum LoginStep {
  PHONE = 'phone',
  OTP = 'otp',
  ORGANISATION = 'organisation',
  NEXT_STEP = 'next-step',
  EMAIL_VERIFICATION = 'email-verification',
}

export enum SignupStep {
  GET_STARTED = 'get-started',
}

export enum SigninStep {
  CREATE_ACCOUNT = 'create-account',
  EMAIL_VERIFICATION = 'email-verification',
}
export interface Country {
  id: string;
  countryCode: string;
  label: string;
  symbol: string;
}

export interface UserDetails {
  id: string;
  dateCreated: Date;
  dateUpdated: Date;
  name: string | null;
  email: string;
  phone: string;
  countryCode: string;
  state?: any;
  city?: any;
  timezone?: any;
  currencyCode?: any;
  provider: string;
  isOrgOwner: boolean;
  access: Access;
}
export enum RefreshTypes {
  TEMPLATES = 'TEMPLATES',
}

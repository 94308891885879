import { SetStateActionWithReset } from './../../../hooks/usePaginatedQuery';
import { SetStateAction } from 'jotai';
import { NavigateFunction } from 'react-router-dom';
import { ROUTES } from '../../../routes/auth-routes/routes';
import { Country, LoginStep, VerifyOtpResponse } from '../typings';
import validateAndTruncatePhoneNumber from './validate-phone';
import { loginWithOtp } from './login-with-otp';
import { firebaseSignInWithCustomToken } from '../../../infra/auth/firebase-auth';

interface verifyOtpUseCaseProps {
  setAuthState: (
    update: SetStateAction<{
      isAuthReady: boolean;
      isCheckingAuth: boolean;
      isOrgSelected: boolean;
      isIntegrationEnabled: boolean;
      isPermissionsReady: boolean;
      makePayment: boolean;
    }>
  ) => void;
  navigate: NavigateFunction;
  otp: string;
  country: Country;
  phone: string;
  resetPhoneNumber: () => void;
  resetUserDetails: () => void;
  setLoginStep: (update: SetStateAction<LoginStep>) => void;
  setPhoneNumber?: (
    update: SetStateActionWithReset<{
      country: Country;
      phone: string;
    }>
  ) => void;
  addNotification: (
    arg: Partial<Notification> & {
      message: string;
    }
  ) => void | Promise<void>;
}

export const verifyOtpUseCase = async ({
  setAuthState,
  navigate,
  otp,
  country,
  phone,
  resetPhoneNumber,
  resetUserDetails,
  setLoginStep,
  setPhoneNumber,
  addNotification,
}: verifyOtpUseCaseProps): Promise<VerifyOtpResponse | null> => {
  try {
    const phoneNumber = validateAndTruncatePhoneNumber({
      phone,
      countryCode: country.countryCode,
      countrySymbol: country.symbol,
    });
    const response = await loginWithOtp(otp, phoneNumber);

    await firebaseSignInWithCustomToken(response.token);
    if (setPhoneNumber) {
      setPhoneNumber({ country, phone });
    }

    if (response?.user?.isNewUser) {
      navigate(ROUTES.onboardPage);
    } else {
      navigate(ROUTES.homePage);
    }

    setLoginStep(LoginStep.PHONE);
    resetPhoneNumber();
    return response;
  } catch (error) {
    setLoginStep(LoginStep.OTP);
    throw error;
  } finally {
    resetUserDetails();
  }
};

import { get, post } from '../../../infra/rest';
import {
  ClientWalletRechargeAPIResponse,
  ExportPaymentHistoryResponse,
  PaymentHistoryAPIResponse,
  WalletConfigType,
  WalletRechargeType,
} from '../types';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';

export const getWalletConfig = async (): Promise<WalletConfigType> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/wallet`;
  return get<WalletConfigType>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const getPaymentHistory = async (
  nextLogDateCreated?: number
): Promise<PaymentHistoryAPIResponse> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  let url = `/v1/channel-partner/${channelDetails.id}/wallet/logs?nextLogDateCreated=${nextLogDateCreated}&limit=10`;
  if (!nextLogDateCreated) {
    url = `/v1/channel-partner/${channelDetails.id}/wallet/logs?limit=10`;
  }
  return get<PaymentHistoryAPIResponse>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const exportPaymentHistoryExcel =
  async (): Promise<ExportPaymentHistoryResponse> => {
    const channelDetails = getChannelDetails();
    if (!channelDetails?.id) {
      throw new Error('Channel details not found');
    }
    const url = `/v1/channel-partner/${channelDetails.id}/wallet/logs/export-excel`;
    return get<ExportPaymentHistoryResponse>(
      url,
      {
        useDefaultHeaders: false,
      },
      true,
      false,
      false,
      true,
      true
    );
  };

export const rechargeWallet = async ({
  amount,
}: {
  amount: number;
}): Promise<WalletRechargeType> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/wallet`;
  return post<WalletRechargeType>(
    url,
    {
      body: JSON.stringify({ amount }),
      useDefaultHeaders: false,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const rechargeCustomerWallet = async ({
  credits,
  orgId,
}: {
  credits: number;
  orgId: string;
}): Promise<ClientWalletRechargeAPIResponse> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/customer/${orgId}/wallet/credits`;
  return post<ClientWalletRechargeAPIResponse>(
    url,
    {
      body: JSON.stringify({ credits }),
      useDefaultHeaders: false,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    true,
    false,
    false,
    true,
    true
  );
};

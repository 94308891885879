import React from 'react';
import MainHeader from '../modules/header';
import { Box } from '@mui/material';

const withHeader = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    return (
      <>
        <MainHeader />
        <Box mt={'60px'}>
          <Component {...props} />
        </Box>
      </>
    );
  };
};

export default withHeader;

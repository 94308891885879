/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  css,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ProfileCell from '../../organisations/components/ProfileCell';
import Button from '@mui/material/Button';
import { AssignCreditSteps, SelectOrganisationProps } from '../types';
import { OrgListCustomersProps } from '../../home/types';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import getOrganisationAPI from '../../organisations/request/getOrganisation';
import { formatCurrency } from '../../../utils/currencyFormatter';
import { organisationsSearchTermAtom } from '../../organisations/states';
import { useAtom, useAtomValue } from 'jotai';
import Loader from '../../../components/loader';
import { walletConfigAtom } from '../state';

const SelectOrganisation = ({
  selectedOrg,
  setCurrentStep,
  setSelectedOrg,
}: SelectOrganisationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const walletConfig = useAtomValue(walletConfigAtom);

  const [orgCustomers, setOrgCustomers] = useState<
    OrgListCustomersProps[] | []
  >([]);

  const [searchValue, setSearchValue] = useAtom(organisationsSearchTermAtom);

  useEffect(() => {
    const getSetOrgDetails = async () => {
      const channelDetails = getChannelDetails();
      if (!channelDetails?.id) {
        return;
      }
      try {
        setIsLoading(true);
        const response = await getOrganisationAPI(channelDetails?.id, {
          search: searchValue,
        });
        setIsLoading(false);
        if (response.customers) {
          setOrgCustomers(response.customers);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    };
    getSetOrgDetails();
  }, [searchValue]);

  const handleOrgChange = (org: OrgListCustomersProps) => {
    setSelectedOrg(org);
  };

  const renderTableRows = () => {
    if (isLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 320px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Loader size={32} />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (!isLoading && (orgCustomers ?? []).length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 320px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                No org found
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {(orgCustomers ?? []).map((orgCustomer, index) => (
          <TableRow
            key={orgCustomer.credits + orgCustomer.name + index}
            onClick={() => {
              setSelectedOrg(orgCustomer);
            }}
            sx={{
              cursor: 'pointer',
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              <Radio
                checked={selectedOrg?.id === orgCustomer.id}
                onChange={() => handleOrgChange(orgCustomer)}
                value={orgCustomer.id}
                name="selectedOrg"
                inputProps={{ 'aria-label': orgCustomer.name }}
              />
              <ProfileCell maxLetters={50} name={orgCustomer.name} />
            </TableCell>
            <TableCell align={'right'}>
              {formatCurrency(
                orgCustomer.credits ?? 0,
                orgCustomer.currencyCode ?? walletConfig.data?.currencyCode
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Box
      sx={{
        maxHeight: 'calc(90vh - 57px)',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        fontWeight={400}
        fontSize={14}
        mb={1}
        color={'#2D2D2DDE'}
        sx={{
          flex: '0 1 auto',
        }}
      >
        Select organisations to assign credits to
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          flex: '0 1 auto',
        }}
      >
        <Grid item xs={true}>
          <TextField
            placeholder="Search by name or number..."
            variant="outlined"
            fullWidth
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* <Grid item xs={4}>
          <FormControl fullWidth size={'small'}>
            <Select
              value={filter}
              onChange={(e) => setAge(e.target.value)}
              IconComponent={KeyboardArrowDownIcon}
              SelectDisplayProps={{}}
            >
              <MenuItem value={'Recently added'}>Recently added</MenuItem>
              <MenuItem value={'Oldest'}>Oldest</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          flex: 1,
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Organisation name</TableCell>
              <TableCell align={'right'}>Amount</TableCell>
            </TableRow>
          </TableHead>
          {renderTableRows()}
        </Table>
      </TableContainer>
      <Button
        onClick={() => setCurrentStep(AssignCreditSteps.ASSIGN_CREDIT)}
        variant="contained"
        disabled={!selectedOrg}
        sx={{
          width: 'fit-content',
          borderRadius: 2,
          marginLeft: 'auto',
          flex: '0 1 auto',
        }}
      >
        Select and proceed
      </Button>
    </Box>
  );
};

export default SelectOrganisation;

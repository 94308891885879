import {
  CurrentSelectedPlanCurrencyCodeAtomType,
  PlanUnitType,
} from './../types/index';
import { atom } from 'jotai';
import {
  CreateOrgCompanyDetailsType,
  CurrentSelectedPlanDetails,
  NewUserBillingDetailsType,
  PlansPricingAPIResponseType,
} from '../types';
import { createRequestAtom } from '../../../utils/atoms/request-atom';
import {
  getCountryCodeFromLocalStorage,
  getCountryFromCountryCode,
  getDefaultCountry,
} from '../../../utils/countries-mapper';

export const newOrgDetailsAtom = atom<CreateOrgCompanyDetailsType>({
  orgName: '',
  loginNumber: '',
  ownerFullName: '',
  country:
    getCountryFromCountryCode(getCountryCodeFromLocalStorage()) ??
    getDefaultCountry(),
});

export const newOrgBillingDetailsAtom = atom<NewUserBillingDetailsType>({
  countryCode: '',
  state: '',
  city: '',
  pinCode: '',
  email: '',
  billingTaxNumber: '',
  address: '',
});

export const planPricingAtom = createRequestAtom<PlansPricingAPIResponseType>(
  {}
);

export const currentSelectedPlanAtom = atom<CurrentSelectedPlanDetails>({
  id: null,
  numUsers: 0,
  label: '',
  unit: PlanUnitType.Month,
  period: 1,
  sellPrice: 0,
});

export const currentSelectedPlanCurrencyCodeAtom =
  atom<CurrentSelectedPlanCurrencyCodeAtomType>({
    currencyCode: 'INR',
  });

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { useAtomValue } from 'jotai';
import { orgProfileAtom } from '../states';

const ProfileSubHeader = () => {
  const orgProfileValue = useAtomValue(orgProfileAtom);

  return (
    <Box
      width={'100%'}
      css={css`
        height: 56px;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #e0e0ea;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      `}
    >
      <Box display={'flex'} gap={'8px'} alignItems={'center'}>
        <IconButton
          css={css`
            color: #2d2d2d;
          `}
          onClick={() => window.history.back()}
        >
          <WestRoundedIcon />
        </IconButton>
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          {orgProfileValue?.data?.org?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileSubHeader;

/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { css } from '@emotion/react';

import withHeader from '../../HOC/WithHeader';
import OrgSubHeader from './components/header';
import SearchAndFilters from './components/SearchAndFilters';
import OrgListTable from './components/OrgListTable';

const OrganisationsPage = () => {
  return (
    <div>
      <OrgSubHeader />
      <Box
        css={css`
          margin: 20px 80px;
        `}
      >
        <Typography
          css={css`
            color: #7a7a7a;
            font-size: 14px;
            font-weight: 500;
            padding-top: 24px;
            padding-bottom: 12px;
          `}
        >
          Create and manage organizations, their settings and members
        </Typography>
        <SearchAndFilters />
        <OrgListTable />
      </Box>
    </div>
  );
};

export default withHeader(OrganisationsPage);

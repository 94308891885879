import { RESET } from 'jotai/utils';
import { useAtom, WritableAtom } from 'jotai';
import { PaginatedQueryRequest } from '../typings/request';
import paginationMerger from './../utils/pagination-merger';
import { useCallback, useEffect, useRef } from 'react';
import { RefreshTypes } from '../modules/login/typings';

export type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

interface DefaultPaginationTypes {
  hasMore: boolean;
  hasMoreLogs?: boolean;
}

export interface UsePaginatedQueryResult<T, K> {
  data: Array<T>;
  paginationData: K & DefaultPaginationTypes;
}

interface UsePaginatedQueryArgs<T, K> {
  getter: (
    paginationData: K | undefined,
    args?: Record<string, unknown>
  ) => Promise<UsePaginatedQueryResult<T, K>>;
  merger?: (data: Array<T> | undefined, newData: Array<T>) => Array<T>;
  requestAtom: WritableAtom<
    PaginatedQueryRequest<T, K>,
    SetStateActionWithReset<PaginatedQueryRequest<T, K>>,
    void
  >;
  refreshEventType?: RefreshTypes;
  cacheData?: boolean;
  autoFetch?: boolean;
}

/**
 * Pagination query to fetch data based on passed getter function and manages pagination data
 * this hook gives several option to manage data form getter
 * @param getter fn to fetch or get data -> when getter method changes, it will be called to update the data
 * @param merger fn to merge pagination data -> can be used to process returned data from the getter before merging to the request atom
 * @param requestAtom - jotai atom to store the data
 * @param refreshEventType - if refresh method is called with event type which matches the passed event, data getter method will be called
 *
 * @returns {
 *    requestData: PaginatedQueryRequest<T, K>,
 *    setRequestData: (update: SetStateActionWithReset<PaginatedQueryRequest<T, K>>) => void,
 *    fetchMore: () => Promise<...>,
 *    refresh: (args) => Promise<...>
 *  }
 */
export default function usePaginatedQuery<T, K>({
  getter,
  merger = (data: Array<T> | undefined, newData: Array<T>) =>
    paginationMerger<T>(data, newData),
  requestAtom,
  cacheData = false,
  autoFetch = true,
}: UsePaginatedQueryArgs<T, K>) {
  const [requestData, setRequestData] = useAtom(requestAtom);

  const mergerRef = useRef(merger);
  const requestDataRef = useRef(requestData);

  mergerRef.current = merger;
  requestDataRef.current = requestData;

  const fetcher = useCallback(
    async ({
      data,
      paginationData,
      fetchingNextPage = false,
      isRefresh = false,
      args,
    }: {
      data?: Array<T>;
      paginationData?: UsePaginatedQueryResult<T, K>['paginationData'];
      fetchingNextPage?: boolean;
      isRefresh?: boolean;
      args?: Record<string, unknown>;
    } = {}) => {
      setRequestData((prev) => ({
        ...prev,
        loading: !fetchingNextPage,
      }));

      if (cacheData && requestDataRef.current.data && !isRefresh) {
        const response = {
          isFetchingMore: false,
          loading: false,
          error: undefined,
          data: requestDataRef.current.data,
          fetched: true,
          paginationData: requestDataRef.current.paginationData,
          refreshing: false,
        };
        setRequestData((prev) => {
          return { ...prev, ...response };
        });
        return Promise.resolve(response);
      }

      try {
        const { data: newData, paginationData: newMetaData } = await getter(
          paginationData,
          args
        );
        const dataToSet = mergerRef.current(
          fetchingNextPage ? data : [],
          newData
        );
        setRequestData((prev) => ({
          ...prev,
          isFetchingMore: false,
          loading: false,
          error: undefined,
          data: dataToSet,
          fetched: true,
          paginationData: newMetaData,
          refreshing: false,
        }));

        return dataToSet;
      } catch (error) {
        // the name will be same for all browser as per https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal
        if ((error as Error).name !== 'AbortError') {
          setRequestData((prev) => ({
            ...prev,
            loading: false,
            isFetchingMore: false,
            refreshing: false,
            error: error as Error,
          }));
        }
      }
    },
    [cacheData, getter, setRequestData]
  );

  const refresh = useCallback(
    async (args?: Record<string, unknown>) => {
      const { data, fetched } = requestData;
      setRequestData((prev) => ({
        ...prev,
        loading: false,
        fetched: fetched,
        refreshing: true,
        isFetchingMore: false,
      }));
      try {
        await fetcher({ data, fetchingNextPage: false, args });
      } catch (error) {
        setRequestData((prev) => ({
          ...prev,
          loading: false,
          fetched: true,
          isFetchingMore: false,
          refreshing: false,
          error: error as Error,
        }));
      }
    },
    [fetcher, requestData, setRequestData]
  );

  const fetchMore = useCallback(async () => {
    const { isFetchingMore, paginationData, data } = requestData;
    if (!isFetchingMore && paginationData?.hasMore) {
      setRequestData((prev) => ({
        ...prev,
        loading: false,
        isFetchingMore: true,
      }));
      await fetcher({ data, paginationData, fetchingNextPage: true });
    }
  }, [fetcher, requestData, setRequestData]);

  useEffect(() => {
    if (autoFetch) {
      const { loading } = requestDataRef.current;
      if (loading) {
        return;
      }

      fetcher();
    } else {
      setRequestData((prev) => ({
        ...prev,
        loading: false,
        refreshing: false,
        error: undefined,
      }));
    }
  }, [autoFetch, fetcher, setRequestData]);

  return {
    requestData,
    setRequestData,
    fetchMore,
    refresh,
    fetcher,
  };
}

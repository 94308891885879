import { IconProps } from '@mui/material';
import { FC } from 'react';

const OrgIconOutlined: FC<IconProps & { fill: string }> = ({
  fill,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
    >
      <path
        d="M12.5 3.83325C10.8887 3.83325 9.58333 5.13859 9.58333 6.74992C9.58333 8.36125 10.8887 9.66658 12.5 9.66658C14.1113 9.66658 15.4167 8.36125 15.4167 6.74992C15.4167 5.13859 14.1113 3.83325 12.5 3.83325ZM12.5 5.49992C13.1901 5.49992 13.75 6.05981 13.75 6.74992C13.75 7.44002 13.1901 7.99992 12.5 7.99992C11.8099 7.99992 11.25 7.44002 11.25 6.74992C11.25 6.05981 11.8099 5.49992 12.5 5.49992ZM6.25 6.33325C5.09766 6.33325 4.16667 7.26424 4.16667 8.41658C4.16667 9.56893 5.09766 10.4999 6.25 10.4999C7.40234 10.4999 8.33333 9.56893 8.33333 8.41658C8.33333 7.26424 7.40234 6.33325 6.25 6.33325ZM18.75 6.33325C17.5977 6.33325 16.6667 7.26424 16.6667 8.41658C16.6667 9.56893 17.5977 10.4999 18.75 10.4999C19.9023 10.4999 20.8333 9.56893 20.8333 8.41658C20.8333 7.26424 19.9023 6.33325 18.75 6.33325ZM6.25 7.7688C6.60807 7.7688 6.89779 8.05851 6.89779 8.41658C6.89779 8.77466 6.60807 9.06437 6.25 9.06437C5.89193 9.06437 5.60221 8.77466 5.60221 8.41658C5.60221 8.05851 5.89193 7.7688 6.25 7.7688ZM18.75 7.7688C19.1081 7.7688 19.3978 8.05851 19.3978 8.41658C19.3978 8.77466 19.1081 9.06437 18.75 9.06437C18.3919 9.06437 18.1022 8.77466 18.1022 8.41658C18.1022 8.05851 18.3919 7.7688 18.75 7.7688ZM12.5 11.3333C11.2695 11.3333 9.27083 11.7239 7.96224 12.4986C7.42838 12.2968 6.84245 12.1666 6.25 12.1666C4.38477 12.1666 2.5 13.332 2.5 14.6112V17.1666H22.5V14.6112C22.5 13.332 20.6152 12.1666 18.75 12.1666C18.1576 12.1666 17.5716 12.2968 17.0378 12.4986C15.7292 11.7239 13.7305 11.3333 12.5 11.3333ZM12.5 12.9999C14.2773 12.9999 16.3509 13.8007 16.6667 14.3053V15.4999H8.33333V14.3053C8.64909 13.8007 10.7227 12.9999 12.5 12.9999ZM6.25 13.8333C6.41602 13.8333 6.5625 13.8625 6.7155 13.8853C6.68294 14.0025 6.66667 14.123 6.66667 14.2499V15.4999H4.16667V14.6764C4.31966 14.4094 5.16602 13.8333 6.25 13.8333ZM18.75 13.8333C19.834 13.8333 20.6803 14.4094 20.8333 14.6764V15.4999H18.3333V14.2499C18.3333 14.123 18.3171 14.0025 18.2845 13.8853C18.4375 13.8625 18.584 13.8333 18.75 13.8333Z"
        fill={fill}
      />
    </svg>
  );
};

export default OrgIconOutlined;

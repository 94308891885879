/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
// import SearchAndFilters from './SearchAndFilters';
import { Card, Typography } from '@mui/material';
import { css } from '@emotion/react';
// import { StatusBadgeTypes } from '../../../../components/badge';
import OrganizationCard from './OrganizationCard';
import { getProflieWabaListAPI } from '../../requests';
import { useLocation } from 'react-router-dom';
import { WabaListAPIResponseType } from '../../types';
import SearchAndFilters from './SearchAndFilters';
import { useAtomValue } from 'jotai';
import { wabaUserSearchQueryAtom } from '../../states';
import Loader from '../../../../components/loader';

const WABABody = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orgId = searchParams.get('orgId');
  const wabaSearchTearm = useAtomValue(wabaUserSearchQueryAtom);
  const [wabaList, setWabaList] = useState<WabaListAPIResponseType>();
  const [isWabaListLoading, setIsWabaListLoading] = useState<boolean>(false);

  useEffect(() => {
    const getSetList = async () => {
      setIsWabaListLoading(true);
      try {
        const response = await getProflieWabaListAPI({
          orgId: orgId!,
          searchTerm: wabaSearchTearm,
        });
        setIsWabaListLoading(false);
        setWabaList(response);
      } catch (e) {
        console.log(e);
        if ((e as Error).name !== 'AbortError') {
          setIsWabaListLoading(false);
        }
      }
    };
    if (!wabaSearchTearm || wabaSearchTearm.length > 2) {
      getSetList();
    }
  }, [orgId, wabaSearchTearm]);

  const renderCards = () => {
    if (isWabaListLoading) {
      return <Loader />;
    }
    if ((wabaList ?? [])?.length === 0) {
      return <Typography>No WABA found</Typography>;
    }
    return (
      <Card
        css={css`
          margin-top: 18px;
          padding: 0 20px;
        `}
      >
        {wabaList?.map((data, index) => (
          <OrganizationCard {...data} key={data.wabaNumber + index} />
        ))}
      </Card>
    );
  };

  if (
    (wabaList ?? [])?.length === 0 &&
    !wabaSearchTearm &&
    !isWabaListLoading
  ) {
    return (
      <div>
        <Typography>No WABA found</Typography>
      </div>
    );
  }

  return (
    <div>
      <SearchAndFilters />
      {renderCards()}
    </div>
  );
};

export default WABABody;

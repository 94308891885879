/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CreditCheckout from './CreditCheckout';
import SelectOrganisation from './SelectOrganisation';
import { AssignCreditsModalProps, AssignCreditSteps } from '../types';
import { OrgListCustomersProps } from '../../home/types';
import { IconButton } from '@mui/material';
import {
  OrgSortAtomReducer,
  OrgSortTypes,
} from '../../organisations/states/reducers/sort.reducers';
import { useSetAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import {
  organisationsSortTermAtom,
  organisationsSearchTermAtom,
} from '../../organisations/states';

const AssignCreditsModal = ({
  open,
  handleClose,
  style,
  step,
  organisation,
}: AssignCreditsModalProps) => {
  const [selectedOrg, setSelectedOrg] = useState<
    OrgListCustomersProps | undefined
  >();

  const [, dispatch] = useReducerAtom(
    organisationsSortTermAtom,
    OrgSortAtomReducer
  );
  const setSearchValue = useSetAtom(organisationsSearchTermAtom);

  const [currentStep, setCurrentStep] = useState<AssignCreditSteps | undefined>(
    step
  );

  const renderStep = () => {
    if (currentStep === AssignCreditSteps.SELECT_ORGANISATION) {
      return (
        <SelectOrganisation
          setCurrentStep={setCurrentStep}
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
        />
      );
    }
    if (currentStep === AssignCreditSteps.ASSIGN_CREDIT) {
      return (
        <CreditCheckout
          setCurrentStep={setCurrentStep}
          selectedOrg={selectedOrg!}
          handleClose={handleClose}
          setSelectedOrg={setSelectedOrg}
        />
      );
    }
  };

  useEffect(() => {
    if (organisation) {
      setCurrentStep(AssignCreditSteps.ASSIGN_CREDIT);
      setSelectedOrg({
        ...organisation.org,
        credits: organisation.wallet?.balance,
      } as unknown as OrgListCustomersProps);
    } else {
      setCurrentStep(AssignCreditSteps.SELECT_ORGANISATION);
    }
    return () => {
      if (!open) {
        setSelectedOrg(undefined);
        setSearchValue('');
        dispatch({ type: OrgSortTypes.reset });
      }
    };
  }, [setSearchValue, dispatch, open, organisation]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 688,
          width: '100%',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          ...style,
        }}
      >
        <Box
          sx={{
            height: 57,
            py: 1,
            px: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <Typography variant="h6" component="h2" fontWeight={600}>
            Assign credits
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: 'calc(90vh - 57px)',
          }}
        >
          {currentStep && renderStep()}
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignCreditsModal;

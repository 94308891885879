import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box, Button, Slide, SlideProps, Snackbar } from '@mui/material';
import AppProtectedRoutes from './app/routes/auth-routes';
import { getI18N } from './i18N';
import AppUnProtectedRoutes from './app/routes/auth-routes/unprotectedRoutes';
import { getFirebaseToken } from './app/infra/auth/firebase-auth';
import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import { firebaseAuth } from './app/infra/firebase/init';
import { Provider } from 'jotai';
import { useAuth } from './app/hooks/useAuth';
import { logoutUseCase } from './app/request/getLogout';
import Loader from './app/components/loader';
import { NotificationsProvider } from './app/notification/notificationsProvider';

interface CustomEventMap {
  reloadpagedata: CustomEvent<{ refreshType: string }>;
}

type TransitionProps = Omit<SlideProps, 'direction'>;
declare global {
  interface Document {
    // Adds definition to Document
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    mozFullScreenEnabled: boolean;
    mozFullScreenElement: boolean;
    webkitFullscreenElement: boolean;
    webkitCurrentFullScreenElement: boolean;
    mozCancelFullScreen: () => Promise<void>;
    webkitCancelFullScreen: () => Promise<void>;
    webkitExitFullscreen: () => Promise<void>;
  }

  interface Window {
    Razorpay: any;
  }
}

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function App({
  updateServiceWorker,
}: {
  readonly updateServiceWorker: (
    registration: ServiceWorkerRegistration
  ) => void;
}) {
  const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
  const [cacheKey, setCacheKey] = useState('');
  const { authState, setAuthState } = useAuth();

  const { t } = getI18N();

  useEffect(() => {
    const handleAuthState = async (user: FirebaseUser | null) => {
      if (user) {
        // User is signed in
        // Here, you can redirect the user to the dashboard or perform other actions

        setCacheKey(Date.now().toString());
        setAuthState({
          isAuthReady: true,
          isCheckingAuth: false,
          isOrgSelected: false,
          isIntegrationEnabled: false,
          isPermissionsReady: false,
          makePayment: false,
        });
      } else {
        // User is not signed in
        // Redirect the user to the login page or show a login form
        await logoutUseCase();
        setAuthState({
          isAuthReady: false,
          isCheckingAuth: false,
          isOrgSelected: false,
          isIntegrationEnabled: false,
          isPermissionsReady: false,
          makePayment: false,
        });
      }
    };

    const unsubscribe = onAuthStateChanged(firebaseAuth, handleAuthState);

    return unsubscribe;
  }, [setAuthState]);

  const fetchToken = useCallback(() => {
    console.log('Token fetching');
    try {
      getFirebaseToken()
        .then((res) => {
          console.log('Token fetched');
          // if (res) {
          //   setIsAuthReady(true);
          // } else {
          //   setIsAuthReady(false);
          // }
          // if(res?.ok){
          //   console.log('Token fetched= ==');
          // }
          // fetchUser();
        })
        .catch((e) => {
          console.log('Token fetched error');
          console.log('error', e);
        });
    } catch (e) {
      console.log('Token fetched error');
      console.log('error', e);
    }
  }, []);

  useEffect(() => {
    const handleUpdateServiceWorker = ($event: Event) => {
      setSnackOpen(true);
      const $customEvent = $event as CustomEvent<{
        registration: ServiceWorkerRegistration;
      }>;
      if ($customEvent?.detail?.registration) {
        updateServiceWorker($customEvent.detail.registration);
      }
    };

    const handleChangeCustomToken = () => {
      fetchToken();
    };
    document.addEventListener('updateServiceWorker', handleUpdateServiceWorker);
    document.addEventListener('changeCustomToken', handleChangeCustomToken);
    return () => {
      document.removeEventListener(
        'updateServiceWorker',
        handleUpdateServiceWorker
      );
      document.removeEventListener(
        'changeCustomToken',
        handleChangeCustomToken
      );
    };
  }, [fetchToken, updateServiceWorker]);

  function renderAuthRoutes() {
    if (authState.isCheckingAuth) {
      return (
        <Box height={'100vh'} width={'100%'}>
          <Loader size={32} secondary={'Loading...'} />;
        </Box>
      );
    }
    if (authState.isAuthReady) {
      return (
        <>
          <NotificationsProvider maxNotifications={5} />
          <AppProtectedRoutes />;
        </>
      );
    } else {
      return (
        <>
          <NotificationsProvider maxNotifications={5} />
          <AppUnProtectedRoutes />;
        </>
      );
    }
  }

  return (
    <Provider key={cacheKey}>
      <Snackbar
        open={isSnackOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        message={t('app_requires_a_reload')}
        TransitionComponent={TransitionUp}
        action={
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('reload')}
          </Button>
        }
      />
      <BrowserRouter>{renderAuthRoutes()}</BrowserRouter>
    </Provider>
  );
}

export default App;

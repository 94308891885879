/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { useAtomValue } from 'jotai';
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Dropdown from './dropdown';
import Loader from '../../components/loader';
import useQuery from '../../hooks/useQuery';
import { Geo, GEO_IP_STORAGE_KEYS } from '../../types';
import {
  getFilteredCountries,
  getCountryFromCountryCode,
  getFlagUrl,
} from '../../utils/countries-mapper';
import { phoneNumberAtom } from '../login/state';
import { Country } from '../login/typings';
import { Typography } from '@mui/material';
import DownChevron from '@mui/icons-material/ChevronLeft';
import usePrevious from '../../hooks/usePrevious';
import { getGeoIp } from '../../request/getGeoIp';
import { geoAtom } from '../../states/phone';

interface PhoneDropdownProps {
  value?: Country;
  containerHeight?: string;
  onChange: (value: Country) => void;
  onGeoIpChange?: (geoIp: Geo) => void;
  onOpen?: () => void;
  onClose?: () => void;
  inputVariant?: 'standard' | 'filled' | 'outlined';
  dropdownCSS?: SerializedStyles;
  ignoreCountryCode?: boolean;
}

const PhoneDropdown = ({
  value,
  onChange,
  onClose,
  onGeoIpChange,
  onOpen,
  inputVariant,
  dropdownCSS,
  ignoreCountryCode,
  containerHeight = 'auto',
}: PhoneDropdownProps) => {
  const [search, setSearch] = useState<string>('');
  const [dropdown, setDropdown] = useState<boolean>(false);
  const prevDropdown = usePrevious<boolean>(dropdown);
  const { country: countryData } = useAtomValue(phoneNumberAtom);
  const initialGeoSet = useRef(false);
  const geoIpGetter = useCallback(async () => {
    const geoData = await getGeoIp({ refresh: false });
    localStorage.setItem(GEO_IP_STORAGE_KEYS.GEO_DATA, JSON.stringify(geoData));
    return geoData;
  }, []);
  const {
    requestData: { loading, error, data },
  } = useQuery<Geo>({
    requestAtom: geoAtom,
    queryFunction: geoIpGetter,
    cacheData: true,
  });
  const countries = useMemo(() => {
    return getFilteredCountries(search);
  }, [search]);

  const toggleDropdown = useCallback(() => {
    setDropdown((dropdown) => !dropdown);
  }, []);

  const onClick = useCallback(
    (value: Country) => {
      setDropdown(false);
      onChange(value);
      setSearch('');
    },
    [onChange, setDropdown, setSearch]
  );

  useEffect(() => {
    if (!loading && !error && data && !initialGeoSet.current && !value) {
      const {
        geo: { countryCode },
      } = data;

      const country = getCountryFromCountryCode(countryCode);

      if (country) {
        onChange(country);
        initialGeoSet.current = true;
      }
    }
  }, [value, data, error, loading, onChange]);

  useEffect(() => {
    if (countryData && !ignoreCountryCode) {
      const country = getCountryFromCountryCode(countryData.countryCode);
      if (country) {
        onChange(country);
      }
    }
  }, [onChange, countryData, ignoreCountryCode]);

  useEffect(() => {
    if (!!data?.geo?.countryCode && !value?.countryCode) {
      const geoCountry = getCountryFromCountryCode(data?.geo?.countryCode);
      if (!!geoCountry?.countryCode && typeof onGeoIpChange === 'function') {
        onGeoIpChange(data);
      }
    }
  }, [data?.geo?.countryCode, onGeoIpChange, value?.countryCode, data]);

  useEffect(() => {
    if (!prevDropdown && dropdown && typeof onOpen === 'function') {
      onOpen();
    } else if (prevDropdown && !dropdown && typeof onClose === 'function') {
      onClose();
    }
  }, [prevDropdown, dropdown, onOpen, onClose]);

  return (
    <Dropdown
      trigger={
        <Typography
          onClick={toggleDropdown}
          onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
            if (event.key === 'Enter') {
              toggleDropdown();
            }
          }}
          css={css(
            `
            border: 1px solid #196d48;
            border-radius: 6px;
            background-color: #e8f4ee;
            cursor: pointer;
            font-size: 16px;
            color: #5a5a5a;
            line-height: 135%;
            letter-spacing: -0.4px;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 4px 6px;
          `,
            inputVariant === 'outlined' && 'height: 40px;'
          )}
        >
          {loading && !countryData ? (
            <Loader size={14} />
          ) : (
            <>
              <span
                css={css`
                  display: flex;
                  color: #5a5a5a;
                  height: ${containerHeight};
                  align-items: center;
                `}
              >
                <img
                  css={css`
                    height: 15px;
                    object-fit: contain;
                  `}
                  alt={`${value?.label ?? ''} flag`}
                  src={getFlagUrl(value?.countryCode ?? '')}
                />
              </span>
              <span>{value?.symbol ?? ''}</span>
              <DownChevron
                css={css`
                  ${dropdown
                    ? 'transform: rotate(90deg)'
                    : 'transform: rotate(-90deg)'}
                `}
              />
            </>
          )}
        </Typography>
      }
      open={dropdown}
      setOpen={setDropdown}
      position="bottom"
    >
      <div
        css={css(
          `
          background: #fff;
          z-index: 10;
          width: 450px;
          padding: 8px;
          margin-top: 4px;
          display: flex;
          flex-direction: column;
          border: 0.5px solid #3f916d;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
          border-radius: 8px;
        `,
          dropdownCSS
        )}
      >
        <input
          autoFocus
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          css={css`
            border: none;
            box-sizing: border-box;
            width: 100%;
            padding: 6px 4px 8px 4px;
            backround: transparent;
            font-size: 17px;
            &:focus {
              outline: none;
            }
          `}
          placeholder="Search countries"
        />
        <div
          css={css`
            box-sizing: border-box;
            width: 100%;
            max-height: 250px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            background: #fff;
            overflow-x: hidden;
            margin-right: 200px;
          `}
          key={search}
        >
          {countries.map(({ countryCode, label, symbol, id }, key) => {
            return (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  font-size: 17px;
                  padding: 8px 12px;
                  border: 0.5px solid #3f916d;
                  background: #3f916d10;
                  border-radius: 4px;
                  cursor: pointer;
                  box-sizing: border-box;
                  max-width: 100%;
                  flex-direction: row;
                `}
                key={key}
                onKeyDown={(event) => {
                  event.stopPropagation();
                  if (event.key === 'Enter') {
                    onClick({ countryCode, label, symbol, id });
                  }
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onClick({ countryCode, label, symbol, id });
                }}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <img
                    css={css`
                      width: 28px;
                      object-fit: contain;
                    `}
                    src={getFlagUrl(countryCode)}
                    alt={`${label} flag`}
                  />
                  <Typography
                    css={css`
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 100%;
                      font-weight: 400;
                      font-size: 17px;
                      padding-left: 8px;
                    `}
                  >
                    {label}
                  </Typography>
                </div>
                <Typography
                  css={css`
                    font-weight: 400;
                    font-size: 17px;
                    flex-shrink: 0;
                  `}
                >
                  {symbol}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </Dropdown>
  );
};

export default PhoneDropdown;

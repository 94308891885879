/** @jsxImportSource @emotion/react */
/* eslint-disable react-hooks/exhaustive-deps */

import { Routes, useLocation, useNavigate } from 'react-router-dom';
import getRoutesV1 from './v1';
import { useCallback, useEffect } from 'react';
import { getOnboardingState } from '../requests/getOnboardingStatus';
import { getChannelDetails } from '../../utils/getSetChannelDetails';
import { ROUTES } from './routes';
import { ChannelPartnerState, ChannelPartnerStateType } from '../../types';
import { useSetAtom } from 'jotai';
import {
  onboardingBillingDetailsAtom,
  onboardingCompanyDetailsAtom,
} from '../../modules/onboarding/states';
import { getCountryFromMobileNumber } from '../../utils/countries-mapper';
import useGetSetDefaultOnboardingState from '../../hooks/useGetSetDefaultOnboardingState';

export default function AppProtectedRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const setOnboardingDetails = useSetAtom(onboardingCompanyDetailsAtom);
  const setOnboardingBillingDetails = useSetAtom(onboardingBillingDetailsAtom);
  const currentStep = new URLSearchParams(location.search).get('step');
  const { getSetDefaultState } = useGetSetDefaultOnboardingState();

  const setOnboardingAtomData = useCallback(
    (response: ChannelPartnerState) => {
      if (response.currentStateMetadata) {
        const partnerDetailsData =
          response?.currentStateMetadata?.SET_PARTNER_DETAILS;
        const partnerBillingData =
          response?.currentStateMetadata?.SET_PARTNER_BILLING;
        if (partnerDetailsData) {
          const savedCountry = getCountryFromMobileNumber(
            partnerDetailsData?.supportNumber
          );
          let savedSupportNumber = partnerDetailsData?.supportNumber;
          if (savedCountry) {
            if (savedSupportNumber) {
              savedSupportNumber = savedSupportNumber.substring(
                savedCountry.symbol.length - 1
              );
            }
          }
          setOnboardingDetails({
            name: partnerDetailsData?.name,
            domain: partnerDetailsData?.domain,
            supportNumber: savedSupportNumber,
            logo: partnerDetailsData?.logoUrl,
            savedCountry: getCountryFromMobileNumber(
              partnerDetailsData?.supportNumber
            ),
          });
        }
        if (partnerBillingData) {
          setOnboardingBillingDetails({
            countryCode: partnerBillingData?.countryCode,
            state: partnerBillingData?.state,
            city: partnerBillingData?.city,
            pinCode: partnerBillingData?.pinCode,
            email: partnerBillingData?.email,
            billingTaxNumber: partnerBillingData?.billingTaxNumber,
            fullName: partnerBillingData?.fullName,
            address: partnerBillingData?.address,
          });
        } else {
          getSetDefaultState();
        }
      }
    },
    [setOnboardingDetails, setOnboardingBillingDetails]
  );

  useEffect(() => {
    let timeOut: NodeJS.Timeout | null = null;
    const redirectNextState = async (isRetry?: boolean) => {
      // setIsLoading(!isRetry);
      const channelDetails = getChannelDetails();
      if (channelDetails?.id) {
        try {
          const response = await getOnboardingState(channelDetails?.id);
          if (response) {
            setOnboardingAtomData(response);
          }
          if (!response?.state) {
            navigate(ROUTES.onboardPage);
          } else if (response?.state === ChannelPartnerStateType.NO_ACTION) {
            if (window.location.pathname === ROUTES.onboardPage) {
              navigate(ROUTES.homePage, { state: { welcomePopup: true } });
            }
          } else {
            if (response?.state !== currentStep) {
              navigate(ROUTES.onboardPage + `?step=${response.state}`);
            }
          }
          if (response?.state === ChannelPartnerStateType.WAITING_FOR_REVIEW) {
            timeOut = setTimeout(() => {
              redirectNextState(true);
            }, 10000);
          }
        } catch (e) {
          // setIsLoading(false);
          console.log(e);
        }
      }
    };
    redirectNextState();
    if (currentStep === ChannelPartnerStateType.WAITING_FOR_REVIEW) {
      redirectNextState(true);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [navigate, currentStep, setOnboardingAtomData]);

  // if (isLoading) {
  //   return (
  //     <Box height={'100vh'} width={'100%'}>
  //       <Loader size={32} secondary={'Loading...'} />;
  //     </Box>
  //   );
  // }

  return <Routes>{getRoutesV1()}</Routes>;
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { LoginStep } from '../typings';
import { ROUTES } from '../../../routes/auth-routes/routes';
import { LoginStepAtom } from '../state';

interface OnboardingHeaderProps {
  headerTitle: string;
  isSignup?: boolean;
}

const OnboardingHeader = ({
  headerTitle,
  isSignup = false,
}: OnboardingHeaderProps) => {
  const setLoginStep = useSetAtom(LoginStepAtom);
  const navigate = useNavigate();

  return (
    <>
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Box
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {/* <Logo width={39} height={39} /> */}
          <Typography
            variant="h1"
            css={css`
              font-size: 22px;
              font-weight: 600;
            `}
          >
            DoubleTick
          </Typography>
        </Box>
        {isSignup && (
          <Button
            onClick={() => {
              setLoginStep(LoginStep.PHONE);
              navigate({ pathname: ROUTES.loginPage });
            }}
            css={css`
              font-size: 16px;
            `}
            variant="outlined"
          >
            Login
          </Button>
        )}
      </Box>
      <Typography
        css={css`
          color: rgb(47, 166, 113);
          font-weight: 700;
          font-size: 24px;
          margin-top: 20px;
        `}
      >
        {headerTitle}
      </Typography>
    </>
  );
};

export default OnboardingHeader;

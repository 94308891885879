import { deleteReq } from '../../../infra/rest';

export const deleteOrgApi = async ({
  orgId,
}: {
  orgId: string;
}): Promise<void> => {
  const url = `/v1/channel-partner/customer/${orgId}`;
  return deleteReq<void>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};

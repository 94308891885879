function getGST(amount: string | number | null): number {
  if (amount === null) {
    return 0;
  }
  const gstRate = 0.18;
  let gstValue = parseFloat(amount.toString()) * gstRate;
  return Math.round(gstValue * 100) / 100;
}

export default getGST;

export function calculateGSTInclusivePrice(
  totalPrice: string | number | null
): number {
  // Calculate the base price (exclusive of GST)
  if (!totalPrice) {
    return 0;
  }
  const price = parseFloat(totalPrice + '');
  const basePrice = price / 1.18;

  return price - basePrice;
}

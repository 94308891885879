import { UserDetails } from '../modules/login/typings';
import { atom } from 'jotai';
import { UserStatusData } from '../types/user';
import { createRequestAtom } from '../utils/atoms/request-atom';

export const rootScope = Symbol('rootScope');

export const userProfileAtom = atom<UserDetails | undefined>(undefined);


export const userRoleAtom = atom((get) => get(userProfileAtom)?.access?.role);

export const userStatusAtom = createRequestAtom<UserStatusData>();

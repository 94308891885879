/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import { css, Global } from '@emotion/react';
import App from './App';
import { APP_BACKGROUD_COLOR } from './app/constants/colors';
import ErrorContainer from './app/components/error';
import theme from './theme';
import i18NInit from './i18N';
import { ThemeProvider } from '@mui/material';

const updateServiceWorker = (registration: ServiceWorkerRegistration) => {
  if (registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    navigator.serviceWorker.oncontrollerchange = () => {
      window.location.reload();
    };
  }
};

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        html,
        body,
        #root {
          margin: 0px;
          padding: 0px;
          height: 100%;
          width: 100%;
          background: ${APP_BACKGROUD_COLOR};
          font-family: 'inter';
        }

        * {
          -webkit-tap-highlight-color: transparent;
          font-family: inherit;
        }

        *,
        :after,
        :before {
          box-sizing: border-box;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0px;
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes shrinker {
          0% {
            stroke-dasharray: 1px, 200px;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -15px;
          }
          100% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -125px;
          }
        }

        @keyframes analytics-widget-flash {
          0% {
            background-color: #ffffff;
          }
          25% {
            background-color: rgba(0, 0, 0, 0.04);
          }
          50% {
            background-color: rgba(93, 183, 121, 0.1);
          }
          75% {
            background-color: rgba(0, 0, 0, 0.04);
          }
          100% {
            background-color: #ffffff;
          }
        }
      `}
    />
  );
};
i18NInit();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <Fragment>
          <GlobalStyles />
          <ErrorContainer
            customCss={css`
              height: 100%;
              width: 100%;
              padding: 16px;
            `}
          />
        </Fragment>
      }
    >
      <ThemeProvider theme={theme}>
        <App updateServiceWorker={updateServiceWorker} />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

/* eslint-disable react-hooks/exhaustive-deps */

/** @jsxImportSource @emotion/react */
import { Box, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CustomSelect, {
  CustomSelectOptions,
} from '../../components/input/CustomSelect';
import WithLabel from '../../components/input/WithLabel';
import { getAddressDetails } from '../onboarding/requests/shippingDetails';

interface CountryStateCitySelectProps {
  children: React.ReactNode;
  countryCode: string | undefined;
  state: string | undefined;
  cityError?: boolean;
  stateError?: boolean;
  countryError?: boolean;
  city: string | undefined;
  onChange: ({
    countryCode,
    state,
    city,
  }: {
    countryCode?: string;
    state?: string;
    city?: string;
  }) => void;
}

const CountryStateCitySelect: React.FC<CountryStateCitySelectProps> = ({
  children,
  countryCode,
  state,
  city,
  onChange,
  cityError,
  stateError,
  countryError,
}) => {
  const [countries, setCountries] = useState<CustomSelectOptions[] | []>([]);
  const [states, setStates] = useState<CustomSelectOptions[] | []>([]);
  const [cities, setCities] = useState<CustomSelectOptions[] | []>([]);
  const [selectedItems, setSelectedItems] = useState({
    countryCode: countryCode ?? '',
    state: state ?? '',
    city: city ?? '',
  });

  const handleSelectChange = (key: string, value: string) => {
    if (key === 'countryCode') {
      setSelectedItems({
        ...selectedItems,
        [key]: value,
        state: '',
        city: '',
      });
    } else if (key === 'state') {
      setSelectedItems({
        ...selectedItems,
        [key]: value,
        city: '',
      });
    } else {
      setSelectedItems({
        ...selectedItems,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    onChange({
      countryCode: selectedItems.countryCode,
      state: selectedItems.state,
      city: selectedItems.city,
    });
  }, [selectedItems.city, selectedItems.countryCode, selectedItems.state]);

  useEffect(() => {
    const getSetCountryOptions = async () => {
      const response = await getAddressDetails({});
      if (Array.isArray(response?.allCountries)) {
        setCountries(
          response.allCountries.map((country) => ({
            label: country.name,
            value: country.id,
          }))
        );
      }
    };
    getSetCountryOptions();
  }, []);

  useEffect(() => {
    const getSetStates = async () => {
      const response = await getAddressDetails({
        countryId: countryCode,
      });
      if (Array.isArray(response?.allStates)) {
        setStates(
          response.allStates.map((state) => ({
            label: state.name,
            value: state.id,
          }))
        );
      }
    };

    if ((states ?? []).length === 0 && state) {
      getSetStates();
    }
  }, [countryCode, states, state]);

  useEffect(() => {
    const getSetCountryOptions = async () => {
      try {
        const response = await getAddressDetails({
          countryId: countryCode,
          stateId: state,
        });

        if (Array.isArray(response?.allStates)) {
          setStates(
            response.allStates.map((state) => ({
              label: state.name,
              value: state.id,
            }))
          );
          setCities([]);
        } else if (Array.isArray(response?.allCities)) {
          setCities(
            response.allCities.map((city) => ({
              label: city.name,
              value: city.cityId!,
            }))
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
    getSetCountryOptions();
  }, [countryCode, state]);

  const disabledCountrySelect = useMemo(() => {
    if (Array.isArray(countries)) {
      return !countries.length;
    }
    return false;
  }, [countries]);

  const disabledStateSelect = useMemo(() => {
    if (Array.isArray(states)) {
      return !states.length;
    }
    return false;
  }, [states]);

  const disabledCitySelect = useMemo(() => {
    if (Array.isArray(cities)) {
      return !cities.length;
    }
    return false;
  }, [cities]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <WithLabel label="Country">
            <CustomSelect
              error={countryError}
              helperText={countryError ? 'Please select a country' : ''}
              options={countries}
              disabled={disabledCountrySelect}
              label={'Select Country'}
              value={countryCode}
              onOptionSelect={(value) =>
                handleSelectChange('countryCode', value)
              }
            />
          </WithLabel>
        </Grid>
        <Grid item xs={6}>
          <WithLabel label="State">
            <CustomSelect
              error={stateError}
              helperText={stateError ? 'Please select a state' : ''}
              options={states}
              disabled={disabledStateSelect}
              value={state}
              label={'Select State'}
              onOptionSelect={(value) => handleSelectChange('state', value)}
            />
          </WithLabel>
        </Grid>
        <Grid item xs={6}>
          <WithLabel label="City">
            <CustomSelect
              error={cityError}
              helperText={cityError ? 'Please select a city' : ''}
              options={cities}
              value={city}
              disabled={disabledCitySelect}
              label={'Select City'}
              onOptionSelect={(value) => handleSelectChange('city', value)}
            />
          </WithLabel>
        </Grid>
        <Grid item xs={6}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CountryStateCitySelect;

import { Country } from '../../../types';

export interface CreateOrgCompanyDetailsType {
  orgName: string;
  ownerFullName: string;
  loginNumber: string;
  country: Country;
}
export interface SaveOrgCompanyRequestDetailsType {
  orgName: string;
  ownerFullName: string;
  loginNumber: string;
}
export interface NewUserBillingDetailsType {
  countryCode: string;
  state: string;
  city: string;
  pinCode: string;
  email: string;
  billingTaxNumber: string;
  address: string;
}
export interface CreateOrgCompanyDetailsResponseType {
  orgId: string;
}
export interface NewUserBillingDetailsTypeResponseType {
  success: boolean;
}

export interface PlanType {
  numUsers: number;
  displayName: string;
  currencyCode: string;
  showPrices: boolean;
  price: PriceType[];
  id: string;
}

export enum PlanUnitType {
  Month = 'month',
  Year = 'year',
}
export interface PriceType {
  id: string;
  amount: number;
  discountAmount: number;
  discountPercent: number;
  unit: PlanUnitType;
  period: number;
  display: boolean;
  tax: boolean;
  taxAmount: number;
  taxPercent: string;
  taxType: string;
}

export interface PlansPricingAPIResponseType {
  plans: PlanType[];
  coupons: {
    valid: boolean;
  };
}

export type PlanDetailsInputPropsPlanDetailsType = (PriceType & {
  displayName: string;
  numUsers: number;
  currencyCode: string;
})[];
export interface PlanDetailsInputProps {
  planDetails: PlanDetailsInputPropsPlanDetailsType;
}

export interface CurrentSelectedPlanDetails {
  id: string | null;
  numUsers: number;
  label: string;
  unit: PlanUnitType;
  period: number;
  sellPrice: number;
}

export interface PlanBreakdownResponseType {
  amountToPay: number;
  availableWalletBalance: number;
  couponDetails: {
    totalDiscount: number;
    totalDiscountPercent: number;
  };
  planName: string;
  subscriptionValue: number;
  taxAmount: number;
  taxPercent: string;
  totalCredits: number;
  isWalletRechargeRequired: boolean;
  totalCreditsToBePurchased: number;
  utilizedCredits: number;
}

export interface CurrentSelectedPlanCurrencyCodeAtomType {
  currencyCode: string;
}

export interface ClientPlanConfirmationAPIResponseType {
  subscriptionId: string;
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import React from 'react';

interface WithMaxLimitProps {
  children: React.ReactNode;
  maxCount: string;
  currentCount: number;
  mt?: string;
}

const WithMaxLimit: React.FC<WithMaxLimitProps> = ({
  children,
  maxCount,
  currentCount,
  mt,
}) => {
  return (
    <Box position="relative" display="inline-flex" width="100%">
      {children}
      <Box
        css={css`
          position: absolute;
          right: 10px;
          top: 22px;
          transform: translateY(-50%);
          font-size: 13px;
          color: #7a7a7a;
        `}
      >
        {currentCount}/{maxCount}
      </Box>
    </Box>
  );
};

export default WithMaxLimit;

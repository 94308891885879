/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { HorizontalRule } from '@mui/icons-material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  getPlanPricingBreakdownAPI,
  postConfirmPlanAPI,
} from '../request/plans';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  currentSelectedPlanAtom,
  currentSelectedPlanCurrencyCodeAtom,
  newOrgBillingDetailsAtom,
  newOrgDetailsAtom,
} from '../state';
import { PlanUnitType } from '../types';
import { formatCurrency } from '../../../utils/currencyFormatter';
import useRazorPayPaymentHook from '../../../hooks/useRazorPayPaymentHook';
import { rechargeWallet } from '../../wallet/request';
import useNotifications from '../../../hooks/useNotifications';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/auth-routes/routes';
import CustomButton from '../../../components/button/CustomButton';
import useRefetchWalletCredits from '../../../hooks/useRefetchWalletCredits';
import useStripePaymentHook from '../../../hooks/useStripePaymentHook';
import { walletConfigAtom } from '../../wallet/state';
import {
  getCountryFromCountryCode,
  getCountryCodeFromLocalStorage,
  getDefaultCountry,
} from '../../../utils/countries-mapper';
import Loader from '../../../components/loader';

interface DetailRowProps {
  title?: string;
  info?: string;
  infoCSS?: string;
  titleCSS?: string;
  priceDataCSS?: string;
  price?: number; // currency
  currency?: string;
  prefix?: string;
  infoToolTip?: string;
  currencyCode?: string;
}

const DetailRow: React.FC<DetailRowProps> = ({
  title,
  info,
  infoToolTip,
  infoCSS,
  titleCSS,
  priceDataCSS,
  price,
  prefix = '',
  currencyCode,
}) => {
  return (
    <Grid container>
      <Grid item>
        <Box>
          <Typography
            css={css(
              `
              color: #2d2d2d;
              font-size: 14px;
            `,
              titleCSS
            )}
          >
            {title}
          </Typography>

          <Typography
            css={css`
              color: #585858;
              font-size: 12px;
              font-weight: 400;
              display: flex;
              gap: 4px;
            `}
          >
            {info}{' '}
            {infoToolTip ? (
              <Tooltip title={infoToolTip} placement="top">
                <ErrorOutlineOutlinedIcon
                  css={css`
                    width: 16px;
                    height: 16px;
                    fill: #585858;
                  `}
                />
              </Tooltip>
            ) : null}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs />
      <Grid>
        <Typography
          css={css(
            `
            color: #2d2d2d;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
          `,
            priceDataCSS
          )}
        >
          {prefix} {formatCurrency(price ?? 0, currencyCode)}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface PlanBreakdownProps {
  createdOrgId: string;
}

const PlanBreakdown: React.FC<PlanBreakdownProps> = ({ createdOrgId }) => {
  const [currentSelectedPlan, setCurrentSelectedPlanAtom] = useAtom(
    currentSelectedPlanAtom
  );
  const walletConfig = useAtomValue(walletConfigAtom);
  const setNewOrgBillingDetailsAtom = useSetAtom(newOrgBillingDetailsAtom);
  const setnewOrgDetailsAtom = useSetAtom(newOrgDetailsAtom);

  const [tax, setTax] = useState<number | null>();
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [usedCredits, setUsedCredits] = useState<number>(0);
  const [totalBeforeDiscount, setTotalBeforeDiscount] = useState<number>(0);
  const [subscriptionValue, setSubscriptionValue] = useState<number>(0);
  const [walletChargeReq, setWalletChargeReq] = useState<boolean>(false);
  const [creditToPurchase, setCreditToPurchase] = useState<number>(0);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const { refreshData } = useRefetchWalletCredits();
  const { makeStripePayment } = useStripePaymentHook();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const selectedCurrencyCode = useAtomValue(
    currentSelectedPlanCurrencyCodeAtom
  );
  const { makeRazPayment } = useRazorPayPaymentHook();

  const resetAtoms = useCallback(() => {
    setCurrentSelectedPlanAtom({
      id: null,
      numUsers: 0,
      label: '',
      unit: PlanUnitType.Month,
      period: 1,
      sellPrice: 0,
    });
    setNewOrgBillingDetailsAtom({
      countryCode: '',
      state: '',
      city: '',
      pinCode: '',
      email: '',
      billingTaxNumber: '',
      address: '',
    });
    setnewOrgDetailsAtom({
      orgName: '',
      loginNumber: '',
      ownerFullName: '',
      country:
        getCountryFromCountryCode(getCountryCodeFromLocalStorage()) ??
        getDefaultCountry(),
    });
  }, [
    setCurrentSelectedPlanAtom,
    setNewOrgBillingDetailsAtom,
    setnewOrgDetailsAtom,
  ]);

  const handleConfirm = useCallback(async () => {
    if (!currentSelectedPlan.sellPrice) {
      addNotification({
        message: 'Please enter the sell price',
        type: 'error',
      });
      return;
    }
    setApiLoading(true);
    try {
      const response = await postConfirmPlanAPI(
        createdOrgId,
        currentSelectedPlan.id!,
        currentSelectedPlan.sellPrice
      );
      setApiLoading(false);
      if (response.subscriptionId) {
        addNotification({
          message: 'Organisation created successfully',
          type: 'success',
        });
        resetAtoms();
        refreshData();
        navigate(ROUTES.homePage, { state: { newOrgCreatedPopup: true } });
      }
    } catch (e) {
      setApiLoading(false);
      addNotification({
        message: (e as Error).message || 'Error in saving data',
        type: 'error',
      });
    }
  }, [
    currentSelectedPlan.sellPrice,
    currentSelectedPlan.id,
    addNotification,
    createdOrgId,
    resetAtoms,
    refreshData,
    navigate,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!walletChargeReq) {
      handleConfirm();
      return;
    }
    if (!currentSelectedPlan.sellPrice) {
      addNotification({
        message: 'Please enter the sell price',
        type: 'error',
      });
      return;
    }
    const rechargeWalletResponse = await rechargeWallet({
      amount: creditToPurchase,
    });
    if (rechargeWalletResponse?.paymentService === 'RAZORPAY') {
      const { orderId, apiKey } = rechargeWalletResponse.paymentData;
      makeRazPayment({
        apiKey,
        orderId,
        amount: creditToPurchase,
        successCallback: handleConfirm,
      });
    } else {
      setApiLoading(true);
      makeStripePayment({
        currentBalance: walletConfig?.data?.balance || 0,
        amount: creditToPurchase + '',
        successCallback: () => {
          // getSetPlanBreakdown(true);
          setApiLoading(false);
        },
        errorCallback: () => setApiLoading(false),
      });
    }
  }, [
    walletChargeReq,
    creditToPurchase,
    currentSelectedPlan.sellPrice,
    handleConfirm,
    makeRazPayment,
    makeStripePayment,
    addNotification,
    // getSetPlanBreakdown,
    walletConfig?.data?.balance,
  ]);

  const resetStates = () => {
    setTax(null);
    setTotalDiscount(0);
    setUsedCredits(0);
    setTotalBeforeDiscount(0);
    setSubscriptionValue(0);
    setWalletChargeReq(false);
    setCreditToPurchase(0);
  };

  const getSetPlanBreakdown = useCallback(
    async (autoSubmit?: boolean) => {
      if (!currentSelectedPlan.id) return;
      try {
        setLoading(true);
        setError(null);
        const response = await getPlanPricingBreakdownAPI(
          currentSelectedPlan.id
        );
        if (response.taxAmount) {
          setTax(parseFloat((response.taxAmount / 2).toFixed(2)));
        }
        setTotalBeforeDiscount(response.subscriptionValue + response.taxAmount);
        setTotalDiscount(response.couponDetails?.totalDiscount ?? 0);
        setSubscriptionValue(response.subscriptionValue);
        setUsedCredits(response.utilizedCredits);
        setWalletChargeReq(response.isWalletRechargeRequired);
        setCreditToPurchase(response.totalCreditsToBePurchased);
        if (!response.isWalletRechargeRequired && autoSubmit) {
          handleSubmit();
        }
      } catch (e) {
        addNotification({
          message: (e as Error).message || 'Error in fetching data',
          type: 'error',
        });
        setError((e as Error).message || 'Error in fetching data');
        resetStates();
      } finally {
        setLoading(false);
      }
    },
    [addNotification, currentSelectedPlan.id, handleSubmit]
  );

  useEffect(() => {
    getSetPlanBreakdown();
  }, [currentSelectedPlan.id]);

  const getPlanPeriod = () => {
    if (currentSelectedPlan.unit === PlanUnitType.Year) {
      return 'Yearly';
    }
    if (currentSelectedPlan.period === 1) {
      return 'Monthly';
    }
    return 'Quarterly';
  };
  let submitBtnText = `Purchase
        ${formatCurrency(creditToPurchase, selectedCurrencyCode.currencyCode)} credits
        and confirm`;
  if (!walletChargeReq) {
    submitBtnText = `Confirm`;
  }

  const getTimeRangeForPlan = (planPeriod: string) => {
    const today = new Date();
    const startDate = new Date(today);
    const endDate = new Date(today);
    if (planPeriod === 'Monthly') {
      endDate.setMonth(today.getMonth() + currentSelectedPlan.period);
    } else if (planPeriod === 'Quarterly') {
      endDate.setMonth(today.getMonth() + currentSelectedPlan.period * 3);
    } else {
      endDate.setFullYear(today.getFullYear() + currentSelectedPlan.period);
    }
    const formatDate = (date: Date) => {
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'short' });
      const year = date.getFullYear().toString().slice(-2); // Last 2 digits of the year
      return `${day} ${month} ${year}`;
    };

    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };
  const planPeriod = getPlanPeriod();

  if ((currentSelectedPlan.id ?? '').toLowerCase().includes('enterprise')) {
    return null;
  }

  const renderBreakdownContent = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <Typography
          css={css`
            color: #000;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
          `}
        >
          You will be charged:
        </Typography>
        <Stack gap={'12px'}>
          <DetailRow
            title={`Base price for ${currentSelectedPlan.label} - ${planPeriod}`}
            price={subscriptionValue}
            info={getTimeRangeForPlan(planPeriod)}
            currencyCode={selectedCurrencyCode.currencyCode}
          />
          {/* <DetailRow
          title="Additional agent seats"
          price="4 x ₹ 500"
          info="24 Aug 23 - 24 Aug 24"
        /> */}
          <HorizontalRule
            css={css`
              width: 100%;
              background: #e0e0e9;
              height: 1px;
            `}
          />
          <DetailRow
            currencyCode={selectedCurrencyCode.currencyCode}
            price={subscriptionValue}
            info="Subtotal"
          />
          {tax ? (
            <>
              <DetailRow
                price={tax}
                currencyCode={selectedCurrencyCode.currencyCode}
                info="CGST (9%)"
              />
              <DetailRow
                price={tax}
                currencyCode={selectedCurrencyCode.currencyCode}
                info="SGST (9%)"
              />
            </>
          ) : null}
          <HorizontalRule
            css={css`
              width: 100%;
              background: #e0e0e9;
              height: 1px;
            `}
          />
          <DetailRow
            price={totalBeforeDiscount}
            title="Total"
            currencyCode={selectedCurrencyCode.currencyCode}
            titleCSS={`
            color: #2D2D2D;
            font-size: 14px;
            font-weight: 600;`}
          />
          {totalDiscount ? (
            <DetailRow
              prefix="-"
              currencyCode={selectedCurrencyCode.currencyCode}
              price={totalDiscount}
              info="Discount"
            />
          ) : null}
          {usedCredits ? (
            <DetailRow
              price={usedCredits}
              prefix="-"
              info="Used credits"
              infoToolTip={''}
              currencyCode={selectedCurrencyCode.currencyCode}
            />
          ) : null}
          <DetailRow
            price={creditToPurchase}
            currencyCode={selectedCurrencyCode.currencyCode}
            title="Total credits to be purchased"
            titleCSS={`
            color: #2D2D2D;
            font-size: 20px;
            font-weight: 600;`}
            priceDataCSS={`
                color: #2D2D2D;
                font-size: 20px;
                font-weight: 600;`}
          />
        </Stack>
        <CustomButton
          loading={loading || apiLoading}
          disabled={loading || apiLoading || !!error}
          variant="contained"
          fullWidth
          css={css`
            margin-bottom: 0;
            margin-top: auto;
          `}
          onClick={handleSubmit}
        >
          {submitBtnText}
        </CustomButton>
      </>
    );
  };
  return (
    <Box
      css={css`
        border-left: 1px solid #e0e0e9;
        background: #fcf8f4;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 64px);
        padding: 28px 36px;
      `}
    >
      {renderBreakdownContent()}
    </Box>
  );
};

export default PlanBreakdown;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import React from 'react';
import PlanTabs from './PlanTabs';

const PlanSetup = () => {
  return (
    <div>
      <Typography
        variant={'h4'}
        css={css`
          color: #2d2d2d;
          font-size: 24px;
          font-weight: 600;
        `}
      >
        Setup plan for your customer
      </Typography>
      <PlanTabs />
    </div>
  );
};

export default PlanSetup;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Button, IconButton, Typography } from '@mui/material';
import React from 'react';

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <Button
      css={css`
        margin-right: 8px;
        padding-left: 28px;
        color: #7a7a7a;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        &:hover {
          background-color: transparent; // Prevents background color change on hover
        }
      `}
      onClick={onClick}
    >
      <IconButton
        css={css`
          position: absolute;
          left: -10px;
          top: 0;
          display: flex;
        `}
      >
        <WestRoundedIcon
          css={css`
            width: 20px;
            height: 20px;
          `}
        />
      </IconButton>
      <Typography
        css={css`
          color: #7a7a7a;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.8;
        `}
      >
        Back
      </Typography>
    </Button>
  );
};

export default BackButton;

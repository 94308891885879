import { IconProps } from '@mui/material';
import { FC } from 'react';

const TwoPeopleIcon: FC<IconProps & { fill?: string }> = ({
  fill,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
    >
      <path
        d="M5.85742 5C7.23599 5 8.35742 3.87857 8.35742 2.5C8.35742 1.12143 7.23599 0 5.85742 0C4.47885 0 3.35742 1.12143 3.35742 2.5C3.35742 3.87857 4.47885 5 5.85742 5ZM5.85742 1.42857C6.45028 1.42857 6.92885 1.90714 6.92885 2.5C6.92885 3.09286 6.45028 3.57143 5.85742 3.57143C5.26456 3.57143 4.78599 3.09286 4.78599 2.5C4.78599 1.90714 5.26456 1.42857 5.85742 1.42857ZM5.89314 8.57143H2.83599C3.54314 8.21429 4.76456 7.85714 5.85742 7.85714C5.93599 7.85714 6.02171 7.86429 6.10028 7.86429C6.34314 7.34286 6.76456 6.91429 7.27171 6.57143C6.75028 6.47857 6.25742 6.42857 5.85742 6.42857C4.18599 6.42857 0.857422 7.26429 0.857422 8.92857V10H5.85742V8.92857C5.85742 8.80714 5.87171 8.68571 5.89314 8.57143ZM11.2146 6.78571C9.90028 6.78571 7.28599 7.50714 7.28599 8.92857V10H15.1431V8.92857C15.1431 7.50714 12.5289 6.78571 11.2146 6.78571ZM12.0788 5.48571C12.6217 5.17857 13.0003 4.6 13.0003 3.92857C13.0003 2.94286 12.2003 2.14286 11.2146 2.14286C10.2289 2.14286 9.42885 2.94286 9.42885 3.92857C9.42885 4.6 9.80742 5.17857 10.3503 5.48571C10.6074 5.62857 10.9003 5.71429 11.2146 5.71429C11.5289 5.71429 11.8217 5.62857 12.0788 5.48571Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default TwoPeopleIcon;

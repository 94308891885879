import { IconProps } from '@mui/material';
import { FC } from 'react';

const LogoutIconSideBar: FC<IconProps> = ({ style, ...props }) => {
  return (
    <svg
      fill="#2d2d2d"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="25"
      height="20"
    >
      <path d="M 11 2 L 11 12 L 13 12 L 13 2 L 11 2 z M 9 2.4589844 C 4.943 3.7339844 2 7.523 2 12 C 2 17.523 6.477 22 12 22 C 17.523 22 22 17.523 22 12 C 22 7.523 19.057 3.7339844 15 2.4589844 L 15 4.5878906 C 17.931 5.7748906 20 8.644 20 12 C 20 16.418 16.418 20 12 20 C 7.582 20 4 16.418 4 12 C 4 8.643 6.069 5.7748906 9 4.5878906 L 9 2.4589844 z"></path>
    </svg>
  );
};

export default LogoutIconSideBar;

import { atom } from 'jotai';

export const authAtom = atom({
  isAuthReady: false,
  isCheckingAuth: true,
  isOrgSelected: false,
  isIntegrationEnabled: false,
  isPermissionsReady: false,
  makePayment: false,
});

import { getWalletConfig } from '../modules/wallet/request';
import { walletConfigAtom } from '../modules/wallet/state';
import { WalletConfigType } from '../modules/wallet/types';
import useQuery from './useQuery';

const useRefetchWalletCredits = () => {
  const {
    requestData: { loading, data },
    refreshData,
  } = useQuery<WalletConfigType>({
    requestAtom: walletConfigAtom,
    queryFunction: getWalletConfig,
    cacheData: true,
  });

  return { refreshData, data, loading };
};

export default useRefetchWalletCredits;

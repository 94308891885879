import { post } from '../../../infra/rest';
import { VerifyOtpResponse } from '../typings';

export const loginWithOtp = async (otp: string, phone: string) => {
  return post<VerifyOtpResponse>(
    `/v1/channel-partner/dashboard/login/verify-otp`,
    {
      body: JSON.stringify({
        phone,
        code: otp,
      }),
    }
  );
};

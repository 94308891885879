/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box } from '@mui/material';
import CurrentPlan from './CurrentPlan';
import BasicInfo from './BasicInfo';
import AgentSeats from './AgentSeats';
import WABAassigned from './WABAassigned';

const ProfileInfoBody = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
      <CurrentPlan />
      <BasicInfo />
      <AgentSeats />
      <WABAassigned />
    </Box>
  );
};

export default ProfileInfoBody;

/** @jsxImportSource @emotion/react */
import { Box, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { css } from '@emotion/react';
import React, { useMemo, useState } from 'react';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import WithLabel from '../../../components/input/WithLabel';
import { useAtom } from 'jotai';
import { newOrgDetailsAtom } from '../state';
import CustomButton from '../../../components/button/CustomButton';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import useNotifications from '../../../hooks/useNotifications';
import { postNewOrgDetails } from '../request';
import PhoneDropdown from '../../countryPicker';
import { Country } from '../../../types';
import WithMaxLimit from '../../../components/input/WithMaxLimit';

interface BasicInfoInputProps {
  handleNext: () => void;
  setCreatedOrgId: (orgId: string) => void;
}

const BasicInfoInput: React.FC<BasicInfoInputProps> = ({
  handleNext,
  setCreatedOrgId,
}) => {
  const [newOrgDetails, setNewOrgDetails] = useAtom(newOrgDetailsAtom);
  const { addNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [isApiError, setIsApiError] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewOrgDetails({
      ...newOrgDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleCountryChange = (selectedCountry: Country) => {
    setNewOrgDetails({
      ...newOrgDetails,
      country: selectedCountry,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsApiError(false);
      setIsLoading(true);
      const channelDetails = getChannelDetails();
      if (channelDetails?.id) {
        const response = await postNewOrgDetails(channelDetails?.id, {
          orgName: newOrgDetails.orgName,
          loginNumber: newOrgDetails.country.symbol + newOrgDetails.loginNumber,
          ownerFullName: newOrgDetails.ownerFullName,
        });
        setIsLoading(false);
        if (response.orgId) {
          setCreatedOrgId(response.orgId);
          handleNext();
        } else {
          addNotification({
            message: 'Something went wrong',
            type: 'error',
          });
        }
      } else {
        setIsLoading(false);
        addNotification({
          message: 'Channel Id not found',
          type: 'error',
        });
      }
    } catch (e) {
      setIsLoading(false);
      setIsApiError(true);
      addNotification({
        message: (e as Error).message || 'Error in saving data',
        type: 'error',
      });
    }
  };

  const errorFields = useMemo(() => {
    if (isApiError) {
      return {
        fullName: newOrgDetails.orgName === '',
        loginNumber: newOrgDetails.loginNumber === '',
        ownerFullName: newOrgDetails.ownerFullName === '',
      };
    }
    return {};
  }, [isApiError, newOrgDetails]);

  return (
    <Box>
      <Typography
        css={css`
          color: #2d2d2d;
          font-size: 24px;
          font-weight: 600;
        `}
      >
        Create new organisation
      </Typography>
      <FormGroup
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: 6px 0;
        `}
      >
        <WithLabel label="Organisation name">
          <WithMaxLimit
            maxCount="100"
            currentCount={(newOrgDetails?.orgName ?? '').length}
          >
            <TextField
              InputProps={{
                style: { paddingRight: '48px' },
              }}
              fullWidth
              name="orgName"
              value={newOrgDetails.orgName}
              placeholder="Enter your company name"
              onChange={handleInputChange}
              error={errorFields.fullName}
              helperText={errorFields.fullName ? 'Name can not be empty' : ''}
              inputProps={{
                maxLength: '100',
              }}
            />
          </WithMaxLimit>
        </WithLabel>
        <Stack spacing={'12px'} direction="row">
          <WithLabel label="Owner's name">
            <WithMaxLimit
              maxCount="50"
              currentCount={(newOrgDetails?.ownerFullName ?? '').length}
            >
              <TextField
                InputProps={{
                  style: { paddingRight: '48px' },
                }}
                inputProps={{
                  maxLength: '50',
                }}
                name="ownerFullName"
                error={errorFields.ownerFullName}
                helperText={
                  errorFields.ownerFullName
                    ? 'Organization name cannot be empty'
                    : ''
                }
                value={newOrgDetails.ownerFullName}
                placeholder="Enter Organization name"
                onChange={handleInputChange}
              />
            </WithMaxLimit>
          </WithLabel>
          <WithLabel label="Login number">
            <Box display={'flex'} gap={1}>
              <PhoneDropdown
                containerHeight={'28px'}
                ignoreCountryCode
                value={newOrgDetails.country}
                onChange={handleCountryChange}
              />
              <TextField
                name="loginNumber"
                type="number"
                error={errorFields.loginNumber}
                helperText={
                  errorFields.loginNumber ? 'Number can not be empty' : ''
                }
                value={newOrgDetails.loginNumber}
                placeholder="Enter number"
                onChange={handleInputChange}
              />
            </Box>
          </WithLabel>
        </Stack>
      </FormGroup>
      <Typography
        css={css`
          padding: 12px 0 20px 0;
          color: #7a7a7a;
          font-size: 14px;
          font-weight: 400;
        `}
      >
        We will send the login credentials to the owner on their WhatsApp number
      </Typography>
      <CustomButton
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        loading={isLoading}
      >
        Proceed{' '}
        <EastRoundedIcon
          css={css`
            margin-left: 8px;
            width: 20px;
          `}
        />
      </CustomButton>
    </Box>
  );
};

export default BasicInfoInput;

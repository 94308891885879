/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import LoginComponent from './components/LoginComponent';
import OtpScreenPage from './otp-screen';
import { LoginStepAtom } from './state';
import { LoginStep } from './typings';

const LoginPage = () => {
  const [loginStep, setLoginStep] = useAtom(LoginStepAtom);
  const {
    authState: { isAuthReady, isOrgSelected },
  } = useAuth();

  useEffect(() => {
    if (!isAuthReady || isOrgSelected) {
      return;
    }
    setLoginStep(LoginStep.ORGANISATION);
  }, [isAuthReady, isOrgSelected, setLoginStep]);

  const renderLoginSteps = () => {
    if (loginStep === LoginStep.PHONE) {
      return <LoginComponent />;
    }

    if (loginStep === LoginStep.OTP) {
      return <OtpScreenPage />;
    }
  };

  return (
    <Box
      css={css`
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(250, 243, 237, 0.59);
      `}
    >
      <Box
        css={css`
          background-color: white;
          border-radius: 4px;
          height: auto;
          max-height: calc(400vh - 40px);
          width: 520px;
          border: 1px solid rgb(227, 219, 203);
          box-shadow: 0px 2px 9px 0px rgba(225, 222, 215, 0.57);
          padding: 35px 27px;
          display: flex;
          flex-direction: column;
          position: relative;
        `}
      >
        {renderLoginSteps()}
      </Box>
    </Box>
  );
};

export default LoginPage;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Otp from './components/otp';
import PhoneNumberComponent from './components/phone-number';
import { ArrowRight } from '@mui/icons-material';
import Loader from '../../../components/loader';
import OnboardingFooter from '../components/OnboardingFooter';
import OnboardingHeader from '../components/OnboardingHeader';
import {
  LoginStepAtom,
  phoneNumberAtom,
  userSignupDetailsAtom,
} from '../state';
import { Button, Typography } from '@mui/material';
import { verifyOtpUseCase } from '../request/verify-otp-use-case';
import useNotifications from '../../../hooks/useNotifications';
import { useSetAuth } from '../../../hooks/useAuth';
import { setChannelDetails } from '../../../utils/getSetChannelDetails';

const OtpScreenPage = () => {
  const setLoginStep = useSetAtom(LoginStepAtom);
  const setAuthState = useSetAuth();
  const navigate = useNavigate();
  const { country, phone } = useAtomValue(phoneNumberAtom);
  const userSignupDetails = useAtomValue(userSignupDetailsAtom);
  const isFromLoginPage = userSignupDetails.firstName.length < 1;
  const [canChangeNumber, setCanChangeNumber] = useState<boolean>(false);
  const { addNotification } = useNotifications();
  const [otp, setOtp] = useState<string>('');
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetUserDetails = useResetAtom(userSignupDetailsAtom);
  const resetPhoneNumber = useResetAtom(phoneNumberAtom);

  const validateForm = useCallback(() => {
    if (otp.length === 6 && phone.length > 0 && !!phone) setIsValid(true);
    else setIsValid(false);
  }, [otp.length, phone]);

  const verifyOtpAndGetStarted = useCallback(async () => {
    try {
      setLoading(true);
      const response = await verifyOtpUseCase({
        setAuthState,
        navigate: navigate as NavigateFunction,
        otp,
        country,
        addNotification,
        phone,
        resetPhoneNumber,
        resetUserDetails,
        setLoginStep,
      });
      if (response)
        setChannelDetails({
          ...response.channelPartner,
          phone: response.user.phone,
        });
    } catch (error) {
      await addNotification({
        message:
          (error as Error).message || 'An error occurred. Please try again',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [
    addNotification,
    country,
    navigate,
    otp,
    phone,
    resetPhoneNumber,
    resetUserDetails,
    setAuthState,
    setLoginStep,
  ]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <>
      <OnboardingHeader
        headerTitle={isFromLoginPage ? 'Login' : 'Start free demo'}
      />
      <PhoneNumberComponent
        componentMarginTop={35}
        canChangeNumber
        isDisabled={!canChangeNumber}
        setCanChangeNumber={setCanChangeNumber}
      />
      <Otp otp={otp} setOtp={setOtp} onSubmit={verifyOtpAndGetStarted} />
      <Button
        css={css`
          margin: auto;
          margin-top: 24px;
          width: 100%;
          padding: 10px 12px;
          display: flex;
          background-color: rgb(47, 166, 113);
        `}
        disabled={!isValid}
        variant="contained"
        onClick={verifyOtpAndGetStarted}
      >
        {loading ? (
          <Loader size="24px" loaderProps={{ sx: { color: 'white' } }} />
        ) : (
          <>
            <Typography
              css={css`
                font-weight: 700;
              `}
            >
              {isFromLoginPage ? 'Login' : 'Start free demo'}
            </Typography>
            <ArrowRight
              height={'24px'}
              width={'30px'}
              // color={!isValid ? 'rgba(0, 0, 0, 0.26)' : '#ffffff'}
            />
          </>
        )}
      </Button>
      <OnboardingFooter />
    </>
  );
};
export default OtpScreenPage;

/** @jsxImportSource @emotion/react */
import { TextField, InputAdornment, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React from 'react';
import { wabaUserSearchQueryAtom } from '../../states';
import { useSetAtom } from 'jotai';

const SearchAndFilters = () => {
  const setSearchValue = useSetAtom(wabaUserSearchQueryAtom);

  return (
    <Grid container gap={'12px'} mb={'12px'} alignItems="center">
      {/* search */}
      <Grid item xs={2.5}>
        <TextField
          id="search-input"
          placeholder="Search by name"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs />

      {/* sort */}
      {/* <Grid item xs={2}>
        <TextField
          id="sort-input"
          placeholder="Sort by name"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SortOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <CloseOutlinedIcon
                  css={css`
                    color: #2d2d2d;
                    height: 16px;
                    width: 16px;
                  `}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="sort-input"
          placeholder="Sort by name"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterAltOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <CloseOutlinedIcon
                  css={css`
                    color: #2d2d2d;
                    height: 16px;
                    width: 16px;
                  `}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid> */}
      <Grid item>
        {/* <Button
          css={css`
            border-radius: 8px;
          `}
          variant="contained"
        >
          Add number
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default SearchAndFilters;

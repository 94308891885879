import { useEffect, useRef, useState } from 'react';
import { SxProps, Typography } from '@mui/material';

interface TimerProps {
  time: number;
  reverse?: boolean;
  onEnd?: () => void;
  sx?: SxProps;
}

function formatTime(timeInSec: number) {
  let hrs = Math.floor(timeInSec / 60);
  const secs = timeInSec - hrs * 60;

  let hrString = hrs.toString();
  let secString = secs.toString();

  if (hrs >= 0 && hrs < 10) {
    hrString = `0${hrs}`;
  }

  if (secs >= 0 && secs < 10) {
    secString = `0${secs}`;
  }

  return { hrs: hrString, secs: secString };
}
const QSTimer = ({ time, reverse = true, onEnd, sx }: TimerProps) => {
  const [currentTime, setCurrentTime] = useState(time);
  const intervalRef = useRef(setInterval(() => {}, 1000));

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentTime((prev) => (reverse ? prev - 1 : prev + 1));
    }, 1000);

    const interval = intervalRef.current;
    return () => {
      clearInterval(interval);
    };
  }, [setCurrentTime, reverse]);

  useEffect(() => {
    if (currentTime > 0) {
      return;
    }
    if (onEnd) {
      clearInterval(intervalRef.current);
      onEnd();
    }
  }, [currentTime, onEnd]);

  const { hrs, secs } = formatTime(currentTime);

  return (
    <Typography sx={sx}>
      {hrs}:{secs}
    </Typography>
  );
};

export default QSTimer;

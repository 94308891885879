/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import React from 'react';

interface WithLabelProps {
  children: React.ReactNode;
  label: string;
  mt?: string;
}

const WithLabel: React.FC<WithLabelProps> = ({ children, label, mt }) => {
  return (
    <Box
      display={'flex'}
      textAlign={'left'}
      flexDirection={'column'}
      gap={'6px'}
      mt={mt}
    >
      <label
        css={css`
          color: #585858;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        `}
      >
        {label}
      </label>
      {children}
    </Box>
  );
};

export default WithLabel;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SetStateAction, Dispatch, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { phoneNumberAtom, userSignupDetailsAtom } from '../../state';
import validateAndTruncatePhoneNumber from '../../request/validate-phone';
import Loader from '../../../../components/loader';
import { generateOtpUseCase } from '../../request/generate-otp-use-case';
import { OTPServices } from '../../typings';
import OTPInput from './otp-input';
import QSTimer from '../../../../components/timer';
import { addEmail } from '../../request/add-email';
import ReCAPTCHA from 'react-google-recaptcha';
import appConfig from '../../../../../config/app';
import useNotifications from '../../../../hooks/useNotifications';
import APIError from '../../../../infra/rest/errors/api-error';

interface OtpProps {
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  isEmailVerification?: boolean;
  onSubmit?: () => void;
  showDashes?: boolean;
}

const Otp = ({ otp, setOtp, onSubmit, isEmailVerification }: OtpProps) => {
  const { country, phone } = useAtomValue(phoneNumberAtom);
  const [loading, setLoading] = useState(false);
  const [showOtpTimer, setShowOtpTimer] = useState(true);
  const userSignupDetails = useAtomValue(userSignupDetailsAtom);
  const { addNotification } = useNotifications();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleResendOtp = useCallback(
    async (service?: string) => {
      try {
        if (loading) {
          return;
        }
        setLoading(true);
        const phoneNumber = validateAndTruncatePhoneNumber({
          phone: phone,
          countryCode: country.countryCode,
          countrySymbol: country.symbol,
        });
        const token = await recaptchaRef?.current?.executeAsync();
        if (!token) {
          addNotification({
            message:
              'Something went wrong while loading recaptcha, Please try again',
            type: 'error',
          });
          return;
        }
        await generateOtpUseCase({
          service: service ?? OTPServices.SMS,
          deviceToken: token,
          countryCode: country.countryCode,
          isResend: false,
          phoneNumber: phoneNumber,
        });
        setShowOtpTimer(true);
      } catch (error) {
        if ((error as APIError).extraData?.errorCode === '1001') {
          addNotification({
            message: 'Please correct the date and time of your device',
            type: 'error',
          });
        } else {
          addNotification({
            message:
              (error as Error).message || 'An error occurred. Please try again',
            type: 'error',
          });
        }
      } finally {
        recaptchaRef.current?.reset();
        setLoading(false);
      }
    },
    [
      country.symbol,
      country.countryCode,
      phone,
      setLoading,
      loading,
      setShowOtpTimer,
      addNotification,
    ]
  );

  const handleResendEmailOtp = useCallback(async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      if (userSignupDetails.email) await addEmail(userSignupDetails.email);
      setShowOtpTimer(true);
    } catch (error) {
      await addNotification({
        message:
          (error as Error).message || 'An error occurred. Please try again',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [userSignupDetails.email, loading, setLoading, addNotification]);

  return (
    <Box
      component="form"
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit?.();
      }}
      css={css`
        width: 100%;
        margin-top: 24px;
        position: relative;
      `}
    >
      <Typography
        css={css`
          font-weight: 600;
        `}
      >
        Enter OTP Received
      </Typography>
      <OTPInput
        value={otp}
        onChange={(val) => {
          setOtp(val);
        }}
        showDashes={false}
      />

      <Typography
        css={css`
          font-size: 16px;
          color: #959595;
          margin-top: 12px;
        `}
      >
        Didn't receive OTP?
      </Typography>
      {isEmailVerification ? (
        <Box
          css={css`
            display: flex;
            align-items: center;
            &:hover > button {
              background-color: none !important;
              box-shadow: none;
            }
          `}
        >
          <Button
            disabled={showOtpTimer}
            variant="text"
            css={css`
              padding: 0px;
              font-size: 16px;
              background: none;
              color: ${showOtpTimer ? '#959595' : 'rgb(47, 166, 113)'};
              cursor: ${showOtpTimer ? 'default' : 'pointer'};
            `}
            onClick={handleResendEmailOtp}
          >
            Resend OTP
          </Button>
          {loading && (
            <Box
              css={css`
                padding-left: 6px;
              `}
            >
              <Loader size={20} />
            </Box>
          )}
          {showOtpTimer && (
            <>
              <span
                css={css`
                  color: #959595;
                  margin-left: 6px;
                `}
              >
                in
              </span>
              <QSTimer
                time={60}
                onEnd={() => setShowOtpTimer(false)}
                sx={{
                  display: 'inline',
                  color: '#959595',
                  paddingLeft: '6px',
                  fontSize: 'inherit',
                }}
              />
            </>
          )}
        </Box>
      ) : (
        <Box
          css={css`
            display: flex;
            align-items: center;
            margin-top: 6px;
          `}
        >
          <Typography
            css={css`
              color: #959595;
            `}
          >
            Resend OTP
          </Typography>
          {loading ? (
            <Box
              css={css`
                padding-left: 6px;
              `}
            >
              <Loader size={20} />
            </Box>
          ) : showOtpTimer ? (
            <>
              <span
                css={css`
                  color: #959595;
                  margin-left: 6px;
                `}
              >
                {' '}
                in
              </span>
              <QSTimer
                time={60}
                onEnd={() => setShowOtpTimer(false)}
                sx={{
                  display: 'inline',
                  color: '#959595',
                  paddingLeft: '6px',
                  fontSize: 'inherit',
                }}
              />
            </>
          ) : (
            <>
              <Typography
                css={css`
                  color: #959595;
                  margin-left: 6px;
                `}
              >
                via
              </Typography>
              <Button
                disabled={showOtpTimer}
                variant="text"
                css={css`
                  padding: 0px;
                  font-size: 16px;
                  font-weight: 400;
                  min-width: auto;
                  margin-left: 6px;
                  color: rgb(47, 166, 113);
                  cursor: pointer;
                  &:disabled {
                    color: #959595;
                  }
                  &:hover {
                    background-color: white;
                  }
                `}
                onClick={() => handleResendOtp(OTPServices.SMS)}
              >
                SMS
              </Button>
              <Typography
                css={css`
                  color: #959595;
                  margin-left: 6px;
                `}
              >
                or{' '}
              </Typography>
              <Button
                disabled={showOtpTimer}
                variant="text"
                css={css`
                  padding: 0px;
                  font-size: 16px;
                  font-weight: 400;
                  min-width: auto;
                  margin-left: 6px;
                  color: rgb(47, 166, 113);
                  cursor: pointer;
                  &:disabled {
                    color: #959595;
                  }
                  &:hover {
                    background-color: white;
                  }
                `}
                onClick={() => handleResendOtp(OTPServices.WHATSAPP)}
              >
                Whatsapp{' '}
              </Button>
            </>
          )}
        </Box>
      )}
      {!isEmailVerification && (
        <ReCAPTCHA
          sitekey={appConfig.recaptchaSiteKey}
          size="invisible"
          ref={recaptchaRef}
        />
      )}
    </Box>
  );
};

export default Otp;

import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

/*
    same  as MUI button but gives a loading spinner when loading prop is true :-)
*/

interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  loading,
  children,
  ...rest
}) => {
  return (
    <Button {...rest} disabled={loading || rest.disabled}>
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};

export default CustomButton;

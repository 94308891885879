import { IconProps } from '@mui/material';
import { FC } from 'react';

const HomeIconOutlined: FC<IconProps & { fill: string }> = ({
  fill,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.66207 14.5884H5.40492V10.017C5.40492 9.75794 5.49254 9.5408 5.66778 9.36556C5.84302 9.19033 6.06016 9.10271 6.31921 9.10271H9.97635C10.2354 9.10271 10.4525 9.19033 10.6278 9.36556C10.803 9.5408 10.8906 9.75794 10.8906 10.017V14.5884H13.6335V6.35985L8.14778 2.24556L2.66207 6.35985V14.5884ZM0.833496 14.5884V6.35985C0.833496 6.07033 0.898258 5.79604 1.02778 5.53699C1.15731 5.27794 1.33635 5.06461 1.56492 4.89699L7.05064 0.782706C7.37064 0.538897 7.73635 0.416992 8.14778 0.416992C8.55921 0.416992 8.92492 0.538897 9.24492 0.782706L14.7306 4.89699C14.9592 5.06461 15.1383 5.27794 15.2678 5.53699C15.3973 5.79604 15.4621 6.07033 15.4621 6.35985V14.5884C15.4621 15.0913 15.283 15.5218 14.9249 15.8798C14.5668 16.2379 14.1364 16.417 13.6335 16.417H9.97635C9.71731 16.417 9.50016 16.3294 9.32492 16.1541C9.14969 15.9789 9.06207 15.7618 9.06207 15.5027V10.9313H7.2335V15.5027C7.2335 15.7618 7.14588 15.9789 6.97064 16.1541C6.7954 16.3294 6.57826 16.417 6.31921 16.417H2.66207C2.15921 16.417 1.72873 16.2379 1.37064 15.8798C1.01254 15.5218 0.833496 15.0913 0.833496 14.5884Z"
        fill={fill}
      />
    </svg>
  );
};

export default HomeIconOutlined;

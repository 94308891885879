import { OrganisationsSortTermAtomType } from '../../types';

export enum OrgSortTypes {
  newest_first = 'newest_first',
  oldest_first = 'oldest_first',
  aToz = 'aToz',
  zToa = 'zToa',
  reset = 'reset',
}

interface ChatListActionsType {
  type: OrgSortTypes;
}

export const OrgSortAtomReducer = (
  prev: OrganisationsSortTermAtomType,
  action: ChatListActionsType
): OrganisationsSortTermAtomType => {
  if (action.type === OrgSortTypes.newest_first) {
    return {
      ...prev,
      sortFilter: 'dateCreated',
      direction: 'DESC',
      key: action.type,
    };
  }
  if (action.type === OrgSortTypes.oldest_first) {
    return {
      ...prev,
      sortFilter: 'dateCreated',
      direction: 'ASC',
      key: action.type,
    };
  }
  if (action.type === OrgSortTypes.aToz) {
    return {
      ...prev,
      sortFilter: 'name',
      direction: 'ASC',
      key: action.type,
    };
  }
  if (action.type === OrgSortTypes.zToa) {
    return {
      ...prev,
      sortFilter: 'name',
      direction: 'DESC',
      key: action.type,
    };
  }
  if (action.type === OrgSortTypes.reset) {
    return {
      sortFilter: 'dateCreated',
      direction: 'DESC',
      key: OrgSortTypes.newest_first,
    };
  }

  return prev;
};

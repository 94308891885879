/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';
import { organisationsTotalOrgs } from '../states';

const OrgSubHeader = () => {
  const totalOrgs = useAtomValue(organisationsTotalOrgs);

  return (
    <Box
      width={'100%'}
      css={css`
        height: 56px;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #e0e0ea;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      `}
    >
      <Box display={'flex'} gap={'8px'}>
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          Organisations
        </Typography>

        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 12px;
            font-weight: 600;
            border-radius: 50%;
            background-color: #e0e0e9;
            padding: 4px 8px;
          `}
        >
          {totalOrgs}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrgSubHeader;

import { Menu, MenuItem } from '@mui/material';

const RowActionMenu = ({
  anchorEl,
  handleClose,
  showDeleteConfirmationModal,
  handleDeleteClick,
}: {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  showDeleteConfirmationModal: boolean;
  handleDeleteClick: () => void;
}) => {
  return (
    <Menu
      id={!!anchorEl ? 'simple-menu' : undefined}
      MenuListProps={{
        'aria-labelledby': 'simple-button',
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      slotProps={{
        paper: {
          style: {
            width: 'fit-content',
          },
        },
      }}
      elevation={4}
    >
      <MenuItem
        selected={showDeleteConfirmationModal}
        onClick={handleDeleteClick}
      >
        Delete
      </MenuItem>
    </Menu>
  );
};

export default RowActionMenu;

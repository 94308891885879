import { get } from '../../infra/rest';
import { ChannelPartnerState } from '../../types';

export const getOnboardingState = async (
  partnerId: string
): Promise<ChannelPartnerState> => {
  return get<ChannelPartnerState>(
    `/v1/channel-partner/${partnerId}/state`,
    undefined,
    true
  );
};

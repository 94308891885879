import { get, post } from '../../../infra/rest';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import {
  ClientPlanConfirmationAPIResponseType,
  PlanBreakdownResponseType,
  PlansPricingAPIResponseType,
} from '../types';

export const getPlanPricingAPI =
  async (): Promise<PlansPricingAPIResponseType> => {
    const partnerId = getChannelDetails()?.id;
    if (!partnerId) {
      throw new Error('Invalid partner ID');
    }
    return get<PlansPricingAPIResponseType>(
      `/v1/channel-partner/${partnerId}/plan-pricing`,
      {
        useDefaultHeaders: false,
      },
      true
    );
  };

export const getPlanPricingBreakdownAPI = async (
  selectedPlan: string
): Promise<PlanBreakdownResponseType> => {
  const partnerId = getChannelDetails()?.id;
  if (!partnerId) {
    throw new Error('Invalid partner ID');
  }
  return get<PlanBreakdownResponseType>(
    `/v1/channel-partner/${partnerId}/plan-pricing/${selectedPlan}/breakdown`,
    {
      useDefaultHeaders: false,
    },
    true
  );
};

export const postConfirmPlanAPI = async (
  orgId: string,
  selectedPlanId: string,
  sellPrice: number
): Promise<ClientPlanConfirmationAPIResponseType> => {
  const partnerId = getChannelDetails()?.id;
  if (!partnerId) {
    throw new Error('Invalid partner ID');
  }
  return post<ClientPlanConfirmationAPIResponseType>(
    `/v1/channel-partner/${partnerId}/customer/${orgId}/plan-pricing/${selectedPlanId}`,
    {
      body: JSON.stringify({
        sellPrice,
      }),
    },
    true
  );
};

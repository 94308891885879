/** @jsxImportSource @emotion/react */
import { Box, Typography, css } from '@mui/material';
import React from 'react';
import HeaderWallet from '../../../icons/HeaderWallet';
import { formatCurrency } from '../../utils/currencyFormatter';
import { walletConfigAtom } from '../wallet/state';
import { useAtomValue } from 'jotai';
import useRefetchWalletCredits from '../../hooks/useRefetchWalletCredits';
import { onboardingCompanyDetailsAtom } from '../onboarding/states';

const MainHeader = () => {
  const walletConfig = useAtomValue(walletConfigAtom);
  useRefetchWalletCredits();
  const onboardingCompanyDetails = useAtomValue(onboardingCompanyDetailsAtom);

  let logoUrl = onboardingCompanyDetails?.logo;
  try {
    if (logoUrl && typeof logoUrl === 'object') {
      logoUrl = URL.createObjectURL(logoUrl);
    }
  } catch (e) {
    console.log(e);
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        top: 0,
        left: 0,
        height: '60px',
        width: '100%',
        zIndex: 1000,
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0ea',
        paddingLeft: '20px',
        paddingRight: '20px',
        background: 'linear-gradient(89deg, #FFF 0%, #F2F2F2 100%)',
      }}
    >
      <img
        height={'auto'}
        css={css`
          max-width: 100px;
          max-height: 50px;
        `}
        src={logoUrl as string}
        alt="logo"
      />
      <Box
        display={'flex'}
        gap={'2px'}
        bgcolor={'#fff'}
        css={css`
          padding: 4px 12px 4px 8px;
          border-radius: 4px;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
        `}
      >
        <HeaderWallet />
        <Typography>
          {formatCurrency(
            walletConfig?.data?.balance ?? 0,
            walletConfig?.data?.currencyCode
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default MainHeader;

import { useAtom } from 'jotai';
import { onboardingBillingDetailsAtom } from '../modules/onboarding/states';
import { GEO_IP_STORAGE_KEYS } from '../types';
import { getAddressDetails } from '../modules/onboarding/requests/shippingDetails';
import { useCallback } from 'react';

const useGetSetDefaultOnboardingState = () => {
  const [onboardingBillingDetails, setOnboardingBillingDetails] = useAtom(
    onboardingBillingDetailsAtom
  );
  const getSetCity = useCallback(
    async (countryCode: string, stateId: string, cityName: string) => {
      const response = await getAddressDetails({
        countryId: countryCode,
        stateId: stateId,
      });
      if (Array.isArray(response?.allCities)) {
        const cityId = response.allCities.find(
          (city) => city.name === cityName
        )?.cityId;
        if (cityId) {
          setOnboardingBillingDetails({
            ...onboardingBillingDetails,
            countryCode: countryCode,
            state: stateId,
            city: cityId,
          });
        }
      }
    },
    [onboardingBillingDetails, setOnboardingBillingDetails]
  );

  const getSetStates = useCallback(
    async (countryCode: string, stateName: string, cityName: string) => {
      const response = await getAddressDetails({
        countryId: countryCode,
      });
      if (Array.isArray(response?.allStates)) {
        const stateId = response.allStates.find(
          (state) => state.name === stateName
        )?.id;
        if (stateId) {
          getSetCity(countryCode, stateId, cityName);
        }
      }
    },
    [getSetCity]
  );

  const getSetDefaultState = useCallback(() => {
    const locationData = JSON.parse(
      localStorage.getItem(GEO_IP_STORAGE_KEYS.GEO_DATA) ?? '{}'
    );
    getSetStates(
      locationData?.geo?.countryCode ?? 'IN',
      locationData?.geo?.regionName ?? '',
      locationData?.geo?.city ?? ''
    );
  }, [getSetStates]);

  return { getSetStates, getSetCity, getSetDefaultState };
};

export default useGetSetDefaultOnboardingState;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { walletConfigAtom } from '../../modules/wallet/state';
import { useAtomValue } from 'jotai';
import { formatCurrency } from '../../utils/currencyFormatter';
import useRefetchWalletCredits from '../../hooks/useRefetchWalletCredits';
// import BGImage from '/walletBg.png';

const WalletCard = () => {
  const walletConfig = useAtomValue(walletConfigAtom);
  useRefetchWalletCredits();

  const renderAmount = () => {
    if (walletConfig.loading) {
      return 'Loading...';
    }
    if (!walletConfig.data) {
      return '0';
    }
    return formatCurrency(
      walletConfig.data.balance,
      walletConfig.data.currencyCode
    );
  };
  return (
    <Box
      height={'200px'}
      position={'relative'}
      width={'100%'}
      display={'flex'}
      borderRadius={'12px'}
      border={'1px solid #00ff0020'}
      justifyContent={'flex-end'}
      alignItems={'flex-start'}
      flexDirection={'column'}
      overflow={'hidden'}
      gap={0}
      css={css`
        background-image: url('/walletBg.png');
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
      `}
    >
      <div
        css={css`
          padding: 20px;
          text-align: left;
        `}
      >
        <Typography
          css={css`
            color: rgba(45, 45, 45, 0.4);
            font-size: 20px;
            font-weight: 500;
          `}
        >
          Your wallet
        </Typography>
        <Typography
          css={css`
            color: rgba(45, 45, 45, 0.7);
            font-size: 40px;
            font-weight: 600;
            padding: 0;
            line-height: 1;
          `}
        >
          {renderAmount()}
        </Typography>
      </div>
    </Box>
  );
};

export default WalletCard;

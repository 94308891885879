import React, { lazy, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSideBar from '../../../HOC/WithSideBar';
import Organisations from '../../../modules/organisations';
import WalletPage from '../../../modules/wallet';
import OrgProfile from '../../../modules/orgProfile';
import { ROUTES } from '../routes';
import AddOrganisation from '../../../modules/addOrganisation';
import LoginComponent from '../../../modules/login';
// import Loader from '../../../../../shared/components/molecules/loader';

const HomeScreenLazyComponentV1 = lazy(() => import('../../../modules/home'));

const OnboardPartnerLazyComponentV1 = lazy(
  () => import('../../../modules/onboarding')
);

// const OrganisationsPageLazyComponentV1 = lazy(
//   () => import('../../../modules/organisations')
// );

export default function getRoutesV1() {
  const loader = <>Loading</>;
  return [
    <Route
      key={ROUTES.onboardPage}
      path={ROUTES.onboardPage}
      element={
        <Suspense fallback={loader}>
          <OnboardPartnerLazyComponentV1 />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.homePage}
      path={ROUTES.homePage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <HomeScreenLazyComponentV1 />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.organisationPage}
      path={ROUTES.organisationPage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <Organisations />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.addOrganisation}
      path={ROUTES.addOrganisation}
      element={
        <Suspense fallback={loader}>
          <AddOrganisation />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.orgProfilePage}
      path={ROUTES.orgProfilePage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <OrgProfile />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.walletPage}
      path={ROUTES.walletPage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <WalletPage />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={'auth_default'}
      path="*"
      element={<Navigate to={ROUTES.homePage} replace />}
    />,
  ];
}

export function getUnprotectedRoutesV1() {
  const loader = <>Loading</>;
  return [
    <Route
      key={ROUTES.loginPage}
      path={ROUTES.loginPage}
      element={
        <Suspense fallback={loader}>
          <LoginComponent />
        </Suspense>
      }
    />,
    <Route
      path="*"
      key={'unprotected_default'}
      element={<Navigate to={ROUTES.loginPage} replace />}
    />,
  ];
}

import { useEffect } from 'react';
export enum RefreshTypes {
  'GROUP_MEMBERS' = 'GROUP_MEMBERS',
  'GROUPS' = 'GROUPS',
  'BOTS' = 'BOTS',
  'CUSTOMERS' = 'CUSTOMERS',
}

export interface UseRefreshArgs {
  refreshEventType?: RefreshTypes;
  onRefresh: () => void;
}

export default function useRefresh({
  refreshEventType,
  onRefresh,
}: UseRefreshArgs): void {
  useEffect(() => {
    const refreshEventHandler = (
      event: CustomEvent<{ refreshType: string }>
    ) => {
      const refreshType = event.detail.refreshType;
      if (refreshType === refreshEventType) {
        onRefresh();
      }
    };
    document.addEventListener('reloadpagedata', refreshEventHandler);
    return () => {
      document.removeEventListener('reloadpagedata', refreshEventHandler);
    };
  }, [refreshEventType, onRefresh]);
}

import { post } from '../../../infra/rest';
import {
  OrgListCustomersResponse,
  OrgListCustomersResponsePaginationArgs,
} from '../../home/types';

interface FilterType {
  search?: string;
  sort?: {
    sortBy: string;
    direction: string;
  };
}
let controller = new AbortController();

const getOrganisationAPI = async (
  partnerId?: string,
  filter?: FilterType,
  nextClientDateCreated?: OrgListCustomersResponsePaginationArgs
) => {
  if (!partnerId) {
    throw new Error('Channel details not found');
  }
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();

  return post<OrgListCustomersResponse>(
    `/v1/channel-partner/${partnerId}/customer/list`,
    {
      body: JSON.stringify({
        searchFilter: filter?.search,
        sortFilter: filter?.sort?.sortBy ? filter.sort : undefined,
        paginationOptions: nextClientDateCreated,
      }),
    },
    true,
    false,
    false,
    false,
    false,
    controller.signal
  );
};

export default getOrganisationAPI;

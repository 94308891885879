/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, InputBase, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import {
  FC,
  SetStateAction,
  Dispatch,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { Country, LoginStep, PhoneNumber } from '../../typings';
import { GREEN } from '../../../../types';
import { validatePhoneNumber } from '../../request/validate-phone';
import { phoneNumberAtom, LoginStepAtom } from '../../state';
import SingleTickIcon from '../../../../../icons/SingleTick';
import PhoneDropdown from '../../../countryPicker';

interface PhoneNumberComponentProps {
  componentMarginTop?: number;
  canChangeNumber?: boolean;
  isDisabled?: boolean;
  setCanChangeNumber?: Dispatch<SetStateAction<boolean>>;
  autoFocus?: boolean;
  phoneNumber?: PhoneNumber;
  isSubmitDisabled?: boolean;
  onSubmit?: () => void;
  setUserPhoneNumber?: Dispatch<SetStateAction<PhoneNumber>>;
  isSendBroadcast?: boolean;
  isPhoneVerified?: boolean;
  setIsValidPhone?: (valid: boolean) => void;
}
const PhoneNumberComponent: FC<PhoneNumberComponentProps> = memo(
  ({
    componentMarginTop,
    canChangeNumber = false,
    isDisabled,
    autoFocus,
    setCanChangeNumber,
    onSubmit,
    isSubmitDisabled,
    isSendBroadcast,
    setUserPhoneNumber,
    phoneNumber,
    isPhoneVerified,
    setIsValidPhone,
  }) => {
    const [{ country, phone }, setPhoneNumber] = useAtom(phoneNumberAtom);
    const setLoginStep = useSetAtom(LoginStepAtom);

    const setCountry = useCallback(
      (value: Country) => {
        if (isSendBroadcast) {
          setUserPhoneNumber?.((no) => ({
            ...no,
            countrySymbol: value.symbol,
            countryCode: value.countryCode,
            countryId: value.id,
          }));
        } else {
          setPhoneNumber((no) => ({
            ...no,
            country: value,
          }));
        }
      },
      [isSendBroadcast, setPhoneNumber, setUserPhoneNumber]
    );

    const setPhone = useCallback(
      (value: string) => {
        if (isSendBroadcast) {
          setUserPhoneNumber?.((no) => ({
            ...no,
            phone: value,
          }));
        }
        setPhoneNumber((no) => ({
          ...no,
          phone: value,
        }));
      },
      [isSendBroadcast, setPhoneNumber, setUserPhoneNumber]
    );

    const handleChangeNumber = () => {
      setCanChangeNumber?.(true);
      setLoginStep(LoginStep.PHONE);
    };

    useEffect(() => {
      setIsValidPhone?.(
        validatePhoneNumber({
          phone: isSendBroadcast ? phoneNumber?.phone! : phone,
          countryCode: isSendBroadcast
            ? phoneNumber?.countryCode!
            : country.countryCode,
          setCountry,
        })
      );
    }, [
      country.countryCode,
      isSendBroadcast,
      phone,
      phoneNumber?.countryCode,
      phoneNumber?.phone,
      setCountry,
      setIsValidPhone,
    ]);

    return (
      <Box
        css={css`
          width: 100%;
          margin-top: ${componentMarginTop}px;
        `}
      >
        <Typography
          css={css`
            font-size: 16px;
            font-weight: 600;
          `}
        >
          WhatsApp Number
        </Typography>
        <div
          css={css(
            css`
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 6px;
              margin-top: 6px;
              border: 1px solid #d3d3d3;
              border-radius: 4px;
            `,
            isDisabled &&
              css`
                pointer-events: none;
                background: #f2f2f2;
              `
          )}
        >
          <PhoneDropdown value={country} onChange={setCountry} />
          <InputBase
            placeholder="Enter your phone number"
            value={isSendBroadcast ? phoneNumber?.phone : phone}
            type="tel"
            disabled={isDisabled}
            autoComplete="off"
            autoFocus={autoFocus}
            endAdornment={
              isPhoneVerified &&
              phone && (
                <div
                  css={css`
                    border-radius: 5px;
                    display: flex;
                    padding: 2px 8px;
                    align-items: center;
                    border: 1px solid rgb(204, 204, 204);
                    background: #ffffff;
                  `}
                >
                  <SingleTickIcon
                    css={css`
                      padding: 2px;
                      background-color: #196d48;
                      border-radius: 50%;
                    `}
                    color="white"
                    size={16}
                  />
                  <Typography
                    css={css`
                      color: #196d48;
                      font-size: 16px;
                      margin-left: 7px;
                    `}
                  >
                    Verified
                  </Typography>
                </div>
              )
            }
            inputProps={{
              sx: {
                padding: '4px 16px 4px 12px',
                fontSize: '16px',
                flex: 1,
              },
            }}
            css={css`
              margin-top: 0 !important;
              width: 100%;
              flex: 1;
              .muiinputbase-root: {
                &::before {
                  border: none;
                }
                &::after {
                  border: none;
                }
              }
            `}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && phone && !isSubmitDisabled) {
                event.preventDefault();
                onSubmit?.();
              }
            }}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
        </div>

        {canChangeNumber && (
          <Box
            css={css(`
              margin-top: 5px;
              font-size: 16px;
              display: flex;
              flex-direction: row;
            `)}
          >
            <Typography
              css={css`
                color: #959595;
              `}
            >
              Wrong number?
            </Typography>
            <Button
              variant="text"
              css={css`
                color: ${GREEN};
                padding-left: 12px;
                cursor: pointer;
                padding: 0;
                font-weight: 400px;
                background: white;
                &:hover {
                  background: white;
                }
              `}
              onClick={handleChangeNumber}
            >
              Change
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

export default PhoneNumberComponent;

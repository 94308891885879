/** @jsxImportSource @emotion/react */
import { Box, FormGroup, TextField, Typography } from '@mui/material';
import { css } from '@emotion/react';
import React, { useCallback, useMemo, useState } from 'react';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import WithLabel from '../../../components/input/WithLabel';
import CustomButton from '../../../components/button/CustomButton';
import { newOrgBillingDetailsAtom } from '../state';
import { useAtom } from 'jotai';
import CountryStateCitySelect from '../../CountryStateCitySelect';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import { putNewOrgDetails } from '../request';
import useNotifications from '../../../hooks/useNotifications';

interface BillingDetailsProps {
  handleNext: () => void;
  createdOrgId: string;
}

const BillingDetails: React.FC<BillingDetailsProps> = ({
  handleNext,
  createdOrgId,
}) => {
  const [newOnboardingBillingDetails, setNewOnboardingBillingDetails] = useAtom(
    newOrgBillingDetailsAtom
  );
  const [isApiError, setIsApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotifications();

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewOnboardingBillingDetails({
      ...newOnboardingBillingDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = useCallback(
    ({
      countryCode,
      state,
      city,
    }: {
      countryCode?: string;
      state?: string;
      city?: string;
    }) => {
      setNewOnboardingBillingDetails((prevDetails) => ({
        ...prevDetails,
        countryCode: countryCode ?? '',
        state: state ?? '',
        city: city ?? '',
      }));
    },
    [setNewOnboardingBillingDetails]
  );

  const handleSubmit = async () => {
    try {
      setIsApiError(false);
      setIsLoading(true);
      const channelDetails = getChannelDetails();
      if (channelDetails?.id) {
        const response = await putNewOrgDetails(
          channelDetails?.id,
          createdOrgId,
          newOnboardingBillingDetails
        );
        console.log(response);
        if (response.success) {
          handleNext();
          return;
        }
        addNotification({
          message: 'An error occurred. Please try again',
          type: 'error',
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsApiError(true);
      setIsLoading(false);
      addNotification({
        message: (e as Error).message ?? 'An error occurred. Please try again',
        type: 'error',
      });
    }
  };

  const errorFields = useMemo(() => {
    if (isApiError) {
      return {
        email: newOnboardingBillingDetails.email === '',
        address: newOnboardingBillingDetails.address === '',
        pinCode: newOnboardingBillingDetails.pinCode === '',
        billingTaxNumber:
          newOnboardingBillingDetails.countryCode !== 'IN' &&
          newOnboardingBillingDetails.billingTaxNumber === '',
        countryCode: newOnboardingBillingDetails.countryCode === '',
        state: newOnboardingBillingDetails.state === '',
        city: newOnboardingBillingDetails.city === '',
      };
    }
    return {};
  }, [isApiError, newOnboardingBillingDetails]);

  return (
    <Box>
      <Typography
        css={css`
          color: #2d2d2d;
          font-size: 24px;
          font-weight: 600;
        `}
      >
        Please provide customer’s billing details
      </Typography>
      <FormGroup
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: 10px 0 20px 0;
        `}
      >
        <WithLabel label="Email ID">
          <TextField
            name="email"
            error={errorFields.email}
            value={newOnboardingBillingDetails.email}
            helperText={errorFields.email ? 'Email is required' : ''}
            placeholder="Enter your email"
            onChange={handleTextInputChange}
          />
        </WithLabel>
        <WithLabel label="Address">
          <TextField
            name="address"
            value={newOnboardingBillingDetails.address}
            error={errorFields.address}
            helperText={errorFields.address ? 'Address is required' : ''}
            placeholder="Enter your address"
            onChange={handleTextInputChange}
          />
        </WithLabel>
        <CountryStateCitySelect
          countryCode={newOnboardingBillingDetails.countryCode}
          state={newOnboardingBillingDetails.state}
          city={newOnboardingBillingDetails.city}
          cityError={errorFields.city}
          stateError={errorFields.state}
          countryError={errorFields.countryCode}
          onChange={handleSelectChange}
        >
          <WithLabel label="Pincode">
            <TextField
              name="pinCode"
              value={newOnboardingBillingDetails.pinCode}
              error={errorFields.pinCode}
              helperText={errorFields.pinCode ? 'Pincode is required' : ''}
              placeholder="Enter area pincode"
              onChange={handleTextInputChange}
            />
          </WithLabel>
        </CountryStateCitySelect>
        {newOnboardingBillingDetails.countryCode === 'IN' && (
          <WithLabel label="Business GST number (Optional)">
            <TextField
              name="billingTaxNumber"
              value={newOnboardingBillingDetails.billingTaxNumber}
              error={errorFields.billingTaxNumber}
              helperText={
                errorFields.billingTaxNumber ? 'GST number is required' : ''
              }
              placeholder="Enter your GST number"
              onChange={handleTextInputChange}
            />
          </WithLabel>
        )}
      </FormGroup>
      <CustomButton
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        loading={isLoading}
      >
        Proceed{' '}
        <EastRoundedIcon
          css={css`
            margin-left: 8px;
            width: 20px;
          `}
        />
      </CustomButton>
    </Box>
  );
};

export default BillingDetails;

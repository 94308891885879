import { useAtom, useSetAtom } from 'jotai';
import { authAtom } from '../states/login';
import { rootScope } from '../states/user';

const useAuth = () => {
  const [authState, setAuthState] = useAtom(authAtom, rootScope);

  return { authState, setAuthState };
};

const useSetAuth = () => {
  const setAuthState = useSetAtom(authAtom, rootScope);

  return setAuthState;
};

export { useAuth, useSetAuth };

/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import WalletCard from '../../components/card/WalletCard';
import {
  Box,
  Grid,
  Typography,
  css,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import withHeader from '../../HOC/WithHeader';
import PaymentHistory from './components/PaymentHistory';
import AddCreditModal from './components/AddCreditModal';
import AssignCreditsModal from './components/AssignCreditsModal';
import useRefetchWalletCredits from '../../hooks/useRefetchWalletCredits';
import { AssignCreditSteps } from './types';

const HomeScreen = () => {
  const [addCreditModalOpen, setAddCreditModalOpen] = useState(false);
  const [assignCreditModalOpen, setAssignCreditModalOpen] = useState(false);
  const { refreshData } = useRefetchWalletCredits();

  return (
    <Box width={'90%'} minHeight={'100vh'} py={'60px'} margin={'0 auto'}>
      {addCreditModalOpen ? (
        <AddCreditModal
          open={addCreditModalOpen}
          handleClose={() => {
            refreshData();
            setAddCreditModalOpen(false);
          }}
        />
      ) : null}
      {assignCreditModalOpen ? (
        <AssignCreditsModal
          open={assignCreditModalOpen}
          step={AssignCreditSteps.SELECT_ORGANISATION}
          handleClose={() => {
            refreshData();
            setAssignCreditModalOpen(false);
          }}
        />
      ) : null}
      <Grid container gap={'12px'} mb={'12px'} alignItems="center">
        <Grid item xs={2.5}>
          <Typography
            css={css`
              color: #2d2d2d;
              font-weight: 600;
              font-size: 24px;
              white-space: nowrap;
            `}
          >
            Your finances
          </Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs={'auto'}>
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Button
              onClick={() => setAssignCreditModalOpen(true)}
              css={css`
                border-radius: 4px;
              `}
              variant="contained"
            >
              Assign credits
            </Button>
            <Button
              onClick={() => setAddCreditModalOpen(true)}
              css={css`
                border-radius: 4px;
              `}
              variant="contained"
            >
              Add credits
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container gap={2} my={'24px'}>
        <Grid item xs={12} md={5}>
          <WalletCard />
        </Grid>
      </Grid>
      <Box
        padding={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          backgroundColor: '#FCF8F4',
          borderRadius: '20px',
        }}
      >
        <Typography
          css={css`
            color: #7a7a7a;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
          `}
        >
          You can use your wallet credits for
        </Typography>
        <List
          dense={true}
          sx={{
            display: 'flex',
            width: 'fit-content',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <ListItem>
            <Box
              sx={{
                backgroundColor: '#3F906D',
                fontSize: '12px',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                marginRight: '10px',
                flexShrink: 0,
              }}
            >
              1
            </Box>
            <ListItemText
              sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              primary="Assigning credits to an organisation"
            />
          </ListItem>
          <ListItem>
            <Box
              sx={{
                backgroundColor: '#3F906D',
                fontSize: '12px',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                marginRight: '10px',
                flexShrink: 0,
              }}
            >
              2
            </Box>
            <ListItemText
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                '@media (min-width: 780px)': {
                  whiteSpace: 'nowrap',
                },
              }}
              primary="Buying DoubleTick subscription for your customers"
            />
          </ListItem>
        </List>
      </Box>
      <PaymentHistory />
    </Box>
  );
};

export default withHeader(HomeScreen);

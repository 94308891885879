import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, isSupported } from 'firebase/messaging';
import config from '../../../config/firebase';

export const firebaseApp = initializeApp(config);
export const firebaseAuth = getAuth(firebaseApp);

export const messaging = getMessaging();

export const getCurrentUserId = () => firebaseAuth.currentUser?.uid;

export const isFirebaseMessagingSupported = async () => {
  return isSupported();
};

/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import WithLabel from '../../../components/input/WithLabel';
import TwoHumanIcon from '../../../../icons/TwoHumanIcon';
import EntContactUsCard from './EntContactUsCard';
import { PlanDetailsInputProps, PlanUnitType } from '../types';
import { useAtom, useAtomValue } from 'jotai';
import {
  currentSelectedPlanAtom,
  currentSelectedPlanCurrencyCodeAtom,
} from '../state';
import { formatCurrency } from '../../../utils/currencyFormatter';

const PlanDetailsInput: React.FC<PlanDetailsInputProps> = ({ planDetails }) => {
  const [selectedPlan, setSelectedPlan] = useAtom(currentSelectedPlanAtom);
  const selectedCurrencyCode = useAtomValue(
    currentSelectedPlanCurrencyCodeAtom
  );

  const options = planDetails
    .map((option) => {
      let amount = option.amount;
      if (!option.display) {
        return null;
      }
      if (!isNaN(option.discountAmount)) {
        amount -= option.discountAmount;
      }
      return {
        value: option.id,
        label: option.displayName,
        numUsers: option.numUsers,
        feeText:
          amount === 0
            ? 'Contact us'
            : `${formatCurrency(amount, selectedCurrencyCode.currencyCode)} one time fee`,
        unit: option.unit,
        period: option.period,
      };
    })
    .filter((option) => option !== null);

  const handlePlanSelect = useCallback(
    (
      id: string,
      numUsers: number,
      label: string,
      unit: PlanUnitType,
      period: number
    ) => {
      setSelectedPlan({ ...selectedPlan, id, numUsers, label, unit, period });
    },
    [setSelectedPlan, selectedPlan]
  );
  const handleSalePriceChange = useCallback(
    (newPrice: string) => {
      setSelectedPlan({ ...selectedPlan, sellPrice: parseFloat(newPrice) });
    },
    [setSelectedPlan, selectedPlan]
  );

  useEffect(() => {
    if (options && options.length > 0) {
      setSelectedPlan({
        ...selectedPlan,
        id: options[0]!.value,
        numUsers: options[0]!.numUsers,
        label: options[0]!.label,
        unit: options[0]!.unit,
        period: options[0]!.period,
      });
    }
  }, [planDetails]); // Trigger this when `options` change

  if (options.length === 0) {
    return <Typography>No options available</Typography>;
  }

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Stack direction={'column'}>
        <RadioGroup
          aria-label="options"
          name="options1"
          css={css`
            display: flex;
            flex-direction: row;
            gap: 12px;
          `}
          value={selectedPlan.id}
        >
          {options.map((option) => {
            if (!option) {
              return null;
            }
            return (
              <Card
                key={option.value}
                css={css(
                  `
                min-width: 220px;
                padding: 4px 10px;
                cursor: pointer;
              `,
                  selectedPlan.id === option.value &&
                    `
                  border: 1px solid #9AE9C4;
              background-color: #EBFBF3;
              `
                )}
                onClick={() =>
                  handlePlanSelect(
                    option.value,
                    option.numUsers,
                    option.label,
                    option.unit,
                    option.period
                  )
                }
              >
                <FormControlLabel
                  value={option.value}
                  sx={{ height: '30px' }}
                  control={
                    <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />
                  } // Adjust the size here
                  label={
                    <span
                      css={css`
                        color: #2d2d2d;
                        font-size: 14px;
                        font-weight: 600;
                      `}
                    >
                      {option.label}
                    </span>
                  }
                />

                <Typography
                  ml={'25px'}
                  css={css`
                    color: #2d2d2d;
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  {option.feeText}
                </Typography>
              </Card>
            );
          })}
        </RadioGroup>
      </Stack>
      {(selectedPlan.id ?? '').toLowerCase().includes('enterprise') ? (
        <EntContactUsCard />
      ) : (
        <DetailsForm
          numUsers={selectedPlan.numUsers}
          planName={selectedPlan.label}
          onSalePriceChange={handleSalePriceChange}
        />
      )}
    </div>
  );
};

interface DetailsFormProps {
  numUsers: number;
  planName: string;
  onSalePriceChange: (newPrice: string) => void;
}

const DetailsForm: React.FC<DetailsFormProps> = ({
  numUsers,
  planName,
  onSalePriceChange,
}) => {
  return (
    <FormGroup
      css={css`
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 8px 0;
      `}
    >
      <WithLabel label="Sale amount">
        <TextField
          type="number"
          onChange={(e) => onSalePriceChange(e.target.value)}
          placeholder="Enter the amount you are selling the plan for"
        />
      </WithLabel>
      <WithLabel label="Number of agents">
        <Card
          css={css`
            padding: 12px 16px;
          `}
        >
          <Stack
            spacing={'12px'}
            direction="row"
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box>
              <Typography
                css={css`
                  color: #2d2d2d;
                  font-size: 14px;
                  font-weight: 600;
                `}
              >
                Included in the {planName}
              </Typography>
              <Typography
                css={css`
                  color: #585858;
                  font-size: 12px;
                  font-weight: 400;
                `}
              >
                Agent seats that you already get in the {planName}
              </Typography>
            </Box>
            <Typography
              css={css`
                color: #2d2d2d;
                font-size: 14px;
                font-weight: 400;
              `}
            >
              <TwoHumanIcon /> {numUsers} agents
            </Typography>
          </Stack>
        </Card>
      </WithLabel>
    </FormGroup>
  );
};

export default PlanDetailsInput;

/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Box, Card, Grid, Typography } from '@mui/material';
import TwoPeopleIcon from '../../../../../icons/TwoPeopleIcon';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { useAtomValue } from 'jotai';
import { orgProfileAtom } from '../../states';
import { calculateDateDifference } from '../../../../utils';

const CurrentPlan = () => {
  const orgProfileValue = useAtomValue(orgProfileAtom);

  return (
    <Box>
      <Card>
        <Grid container>
          <Grid item xs>
            <Typography
              css={css`
                color: #585858;
                font-size: 12px;
                font-weight: 500;
              `}
            >
              Current plan
            </Typography>
            <Typography
              css={css`
                color: #2d2d2d;
                font-size: 18px;
                font-weight: 600;
              `}
            >
              {orgProfileValue?.data?.subscribedPlan?.displayName ?? ''}
            </Typography>
            <Box display={'flex'} gap={'8px'}>
              <Typography
                css={css`
                  color: #2d2d2d;
                  font-size: 12px;
                  font-weight: 400;
                `}
              >
                <TwoPeopleIcon />{' '}
                {orgProfileValue?.data?.subscribedPlan?.numUsers} active agents
              </Typography>
              <Typography
                css={css`
                  color: #2d2d2d;
                  font-size: 12px;
                  font-weight: 400;
                  display: flex;
                  align-items: center;
                `}
              >
                <HourglassEmptyOutlinedIcon
                  css={css`
                    height: 18px;
                    width: 18px;
                  `}
                />{' '}
                Plan expires in{' '}
                {calculateDateDifference(
                  orgProfileValue?.data?.subscribedPlan?.renewalDate ?? ''
                )}{' '}
                days
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item>
            <Typography
              css={css`
                color: #2d2d2d;
                text-align: right;
                font-size: 22px;
                font-weight: 500;
              `}
            >
              ₹20,000/year
            </Typography>
            <Typography
              css={css`
                color: #585858;
                text-align: right;
                font-size: 12px;
                font-weight: 500;
              `}
            >
              paid per year
            </Typography>
            <Box mt={'20px'} display={'flex'} gap={'30px'}>
              <Button>Contact us to change plan</Button>
              <Button variant="contained">Renew</Button>
            </Box>
          </Grid> */}
        </Grid>
      </Card>
    </Box>
  );
};

export default CurrentPlan;
